// src/utils/calculationFunctions.js

/**
 * Extracts numbers from a string and converts it to a floating-point number.
 * @param {number} str - The string to process.
 * @return {number} - The extracted floating-point number.
 */
function extractNumberFromString(str) {
    const cleanedString = str.replace(/[^0-9.]/g, '');
    return parseFloat(cleanedString);
}

/**
 * Extracts currency symbols from a string.
 * @param {number} str - The string containing the currency.
 * @return {string} - The extracted currency symbols.
 */
function extractCurrency(str) {
    return str.replace(/[0-9.,\s]/g, '').trim();
}

/**
 * Formats a number to two decimal places.
 * @param {number} num - The number to format.
 * @return {number} - The number formatted to two decimal places.
 */
function formatToTwoDecimals(num) {
    return parseFloat(num.toFixed(2));
}

const formatCurrency = (currency) => {
    return currency === 'EUR' ? `€` : `${currency}`;
};

/**
 * Calculates the number of vacation days between two dates.
 * @param {string} departureDate - The departure date.
 * @param {string} returnDate - The return date.
 * @return {number} - The number of vacation days.
 */
const calculateVacationDays = (departureDate, returnDate) => {
    const startDate = new Date(departureDate);
    const endDate = new Date(returnDate);
    const timeDifference = endDate - startDate;
    return timeDifference / (1000 * 60 * 60 * 24); // Convert time difference from milliseconds to days
};

/**
 * Reformats a dateTime string to a human-readable format.
 * @param {string} dateTimeString - The dateTime string to modify.
 * @return {string} - The reformatted dateTime string.
 */
const modifyDateTimeString = (dateTimeString) => {
    const dateTimeRegex = /^(\d{4})-(\d{2})-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2}))?$/;
    const match = dateTimeString.match(dateTimeRegex);
    if (!match) {
        console.error('Invalid date-time format:', dateTimeString);
        return dateTimeString;
    }

    const [, year, month, day, hour, minute] = match;

    if (hour && minute) {
        return `${year}-${month}-${day} ${hour}:${minute}`;
    }

    return `${year}-${month}-${day}`;
};

/**
 * Extracts hour-minute string from dateTime format.
 * @param {string} dateTimeString - The dateTime string to modify.
 * @return {string} - The reformatted dateTime string.
 */
const extractTime = (dateTimeString) => {
    const dateTimeRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/; // Ensure 'T' is present and time is mandatory
    const match = dateTimeString.match(dateTimeRegex);

    if (!match) {
        console.error('Invalid date-time format:', dateTimeString);
        return null; // Or return an empty string "" if that's more suitable
    }

    // Correct destructuring (ignore the full match at index 0)
    const [,year, month, day, hour, minute, second] = match;

    return `${hour}:${minute}`;
};

/**
 * Extracts date string in format yyyy-mm-dd from dateTime format.
 * @param {string} dateTimeString - The dateTime string to modify.
 * @return {string} - The reformatted dateTime string.
 */
const extractDate = (dateTimeString) => {
    const dateTimeRegex = /^(\d{4})-(\d{2})-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2}))?$/;
    const match = dateTimeString.match(dateTimeRegex);
    if (!match) {
        console.error('Invalid date-time format:', dateTimeString);
        return dateTimeString;
    }

    const [, year, month, day] = match;

    return `${year}-${month}-${day}`;
};

/**
 * Calculates the duration in minutes between two dateTime strings.
 * @param {string} startDateTime - The starting dateTime.
 * @param {string} endDateTime - The ending dateTime.
 * @return {string} - The duration in minutes.
 */
const calculateDurationInMinutes = (startDateTime, endDateTime) => {
    const start = new Date(startDateTime);
    const end = new Date(endDateTime);
    const differenceInMilliseconds = end - start;

    // Convert milliseconds to minutes
    const durationInMinutes = differenceInMilliseconds / (1000 * 60);

    return `${Math.round(durationInMinutes)} min`; // Round to nearest minute
};

const splitPriceAndCurrency = (priceWithCurrency) => {
  if (typeof priceWithCurrency !== 'string') {
      console.log("problem wih priceWtihCurrency input")
    return [];
  }

  const match = priceWithCurrency.match(/([\d.,]+)([^\d.,]+)/);
  return match ? [match[1].trim(), match[2].trim()] : [];
};

const getGrandTotal = (selectedOffers, offers, flightsSelected, accommodationSelected, transferSelected) => {
    // Calculating Grand total
    let travelOfferTotal = 0;
    let currency = '';
    for (let itemId = 1; itemId <= 4; itemId++) {
        let selectedOptionForItem = selectedOffers?.[itemId];

        if (itemId === 2 && selectedOptionForItem === 0) {
            selectedOptionForItem = 1; // hardcoded for transfers since right now it is a single offer
        }
        if (itemId === 4 && selectedOptionForItem === 0) {
            selectedOptionForItem = 1; // hardcoded for transfers since right now it is a single offer
        }
        let itemPrice = 0;
        let itemPriceReturn = 0;
        try {
            if (itemId === 1 && flightsSelected) {
                itemPrice = offers.offers[itemId - 1].info1["selection-box"][`option${selectedOptionForItem + 1}`].price;
                itemPriceReturn = offers.offers[itemId - 1].info1["selection-box-2"][`option${selectedOptionForItem + 1}`].price;
            }
            if (itemId === 3 && accommodationSelected) {
                itemPrice = offers.offers[itemId - 1].info1["selection-box"][`option${selectedOptionForItem + 1}`].price;
            }
            if (itemId === 2 && selectedOptionForItem && transferSelected) {
                itemPrice = offers.offers[itemId - 1].info1["selection-box"][`option${selectedOptionForItem}`].price;
            }
            if (itemId === 4 && selectedOptionForItem && transferSelected) {
                itemPrice = offers.offers[itemId - 1].info1["selection-box"][`option${selectedOptionForItem}`].price;
            }
        } catch (e) {
            console.log("Looks like price is not available for item ", itemId)
        }

        let itemPriceNumber = 0;
        let itemPriceReturnNumber = 0;
        if (itemPrice !== 0) {
            itemPriceNumber = extractNumberFromString(itemPrice);
            currency = extractCurrency(itemPrice);
        }
        if (itemPriceReturn !== 0) {
            itemPriceReturnNumber = extractNumberFromString(itemPriceReturn);
            currency = extractCurrency(itemPriceReturn);
        }

        travelOfferTotal = travelOfferTotal + itemPriceNumber + itemPriceReturnNumber;

    }
    travelOfferTotal = formatToTwoDecimals(travelOfferTotal);
    travelOfferTotal = travelOfferTotal + currency;

    return travelOfferTotal;
};

const monthNames = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const formatDate = (dateString) => {
  if (!dateString) {
    return "";
  }

  const parts = dateString.split('-');
  if (parts.length !== 3) {
    return dateString; // Return original string if format is invalid
  }

  const year = parts[0];
  const month = parseInt(parts[1]) - 1; // Month is zero-based in JavaScript
  const day = parts[2];

  return `${monthNames[month]} ${day}, ${year}`;
};


// Export all utility functions
export {
    extractNumberFromString,
    extractCurrency,
    formatToTwoDecimals,
    formatCurrency,
    calculateVacationDays,
    modifyDateTimeString,
    calculateDurationInMinutes,
    getGrandTotal,
    extractTime,
    extractDate,
    formatDate,
    splitPriceAndCurrency
};
