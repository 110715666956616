import React, {useEffect, useRef, useState} from 'react';
import '../carousel/detailsPage/details/styles.css';
import './styles.css';
import {ReactComponent as PlaneIconLanding} from '../../../images/icons/plane-landing.svg';
import {ReactComponent as PlaneIconTakeOff} from '../../../images/icons/plane-takeoff.svg';
import {formatDate} from "../../utils/calculationFunctions";

const FlightOfferMoreDetails = ({details, detailsBack}) => {
    const innerContainerRef = useRef(null); // Ref to measure the inner container
    const innerContainerBackRef = useRef(null); // Ref to measure the inner container
    const [outerHeight, setOuterHeight] = useState(0); // State to store outer container height
    const [outerBackHeight, setOuterBackHeight] = useState(0); // State to store outer container height

    useEffect(() => {
        const updateOuterHeight = () => {
            if (innerContainerRef.current) {
                const innerHeight = innerContainerRef.current.offsetHeight;
                setOuterHeight(innerHeight + 35); // Add 35px to the inner container's height
            }
        };

        const updateOuterBackHeight = () => {
            if (innerContainerBackRef.current) {
                const innerBackHeight = innerContainerBackRef.current.offsetHeight;
                setOuterBackHeight(innerBackHeight + 35); // Add 35px to the inner container's height
            }
        };

        // Create a ResizeObserver to watch for changes in the inner container's size
        const resizeObserver = new ResizeObserver(() => {
            updateOuterHeight();
            updateOuterBackHeight();
        });

        if (innerContainerRef.current || innerContainerBackRef.current) {
            resizeObserver.observe(innerContainerRef.current);
            resizeObserver.observe(innerContainerBackRef.current);
        }

        // Cleanup observer on component unmount
        return () => {
            if (innerContainerRef.current) {
                resizeObserver.unobserve(innerContainerRef.current);
            }
            if (innerContainerBackRef.current) {
                resizeObserver.unobserve(innerContainerBackRef.current);
            }
        };
    }, []);

    const calculateLayoverTime = (arrivalTime, departureTime) => {
        const arrival = new Date(arrivalTime);
        const departure = new Date(departureTime);
        const difference = departure - arrival;

        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        return `${hours}h ${minutes}m`;
    };

    const formatDuration = (duration) => {
        const matches = duration.match(/PT(\d+H)?(\d+M)?/);
        const hours = matches[1] ? matches[1].slice(0, -1) : '0';
        const minutes = matches[2] ? matches[2].slice(0, -1) : '0';
        return `${hours}h ${minutes}m`;
    };

    const renderMoreDetailedFlights = (segments, details) => {
        if (!segments || segments.length === 0) return null;
        let checkedBags = "none";
        if (details.travelerInfo.pricingOptions.includedCheckedBagsOnly) {
            checkedBags = "20kg";
        }
        return (
            <div className="flight-segments-container">
                {segments.map((segment, index) => (

                    <div key={index}>
                        {/* Departure Info */}
                        <div className="flight-segment">
                            <div className="flight-segment-departure">
                                <span className="airport-text">{segment.departure.iataCode}</span>
                                <span className="departure-time">
                                    <PlaneIconTakeOff/> {segment.departure.at.slice(11, 16)}
                                </span>
                                <span className={"departure-date"}> {formatDate(segment.departure.at.slice(0, 10))}</span>
                            </div>

                            {/* Flight Duration */}
                            <div className="flight-duration-container">
                                <p className="duration-text more-details">
                                    &lt;- {formatDuration(segment.duration)} -&gt;
                                </p>
                            </div>

                            {/* Arrival Info */}
                            <div className="flight-segment-arrival">
                                <span className="airport-text">{segment.arrival.iataCode}</span>
                                <span className="arrival-time">
                                    <PlaneIconLanding/> {segment.arrival.at.slice(11, 16)}
                                </span>
                                <span className={"arrival-date"}> {formatDate(segment.arrival.at.slice(0, 10))}</span>
                            </div>
                        </div>

                        {details && detailsBack && (
                            <div className="separator-dark-line"></div>
                        )}

                        <div className={"additional-flight-details"}>
                            <div className={"carrier-info"}>
                                <div className={"detail-title more-details"}>Carrier</div>
                                <div className={"detail-text more-details"}>{segment.carrierCode}</div>
                            </div>
                            <div className={"flight-number"}>
                                <div className={"detail-title more-details"}>Flight No.</div>
                                <div className={"detail-text more-details"}>{segment.number}</div>
                            </div>
                            <div className={"aircraft-info"}>
                                <div className={"detail-title more-details"}>Aircraft</div>
                                <div className={"detail-text more-details"}>{segment.aircraft.code}</div>
                            </div>
                            <div className={"terminal-info"}>
                                <div className={"detail-title more-details"}>Terminal</div>
                                <div
                                    className={"detail-text more-details"}>{segment.departure.terminal || "No info"}</div>
                            </div>
                        </div>
                        {index < segments.length - 1 && (
                            <div className="layover-info">
                                <p className="layover-text">
                                    {calculateLayoverTime(segment.arrival.at, segments[index + 1].departure.at)} Layover
                                    ({segment.arrival.iataCode})
                                </p>
                            </div>
                        )}
                    </div>

                ))}
                <h3 className={"monitor-subtitle"}>Traveler Info</h3>
                <div className="separator-dark-line"></div>

                {details.travelerInfo.travelerPricings.map((travelerInfo, index) => (
                    <div key={index} className={"additional-traveler-details"}>
                        <div className={"passenger-info"}>
                            <div className={"detail-title more-details"}>Traveler {travelerInfo.travelerId}</div>
                            <div className={"detail-text more-details"}>{travelerInfo.travelerType}</div>
                        </div>
                        <div className={"price-info"}>
                            <div className={"detail-title more-details"}>Price</div>
                            <div className={"detail-text more-details"}>{travelerInfo.price.total}</div>
                        </div>
                        <div className={"baggage-info"}>
                            <div className={"detail-title more-details"}>Baggage</div>
                            <div className={"detail-text more-details"}>{checkedBags}</div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="offer-details">
            {details && <h2 className={"more-details-title"}>Flight To Destination</h2>}
            <div className="container-info segments-container" style={{height: `${outerHeight}px`, visibility: details ? 'visible' : 'hidden' }}>
                <div className="flight-info-inner">
                    <div ref={innerContainerRef} className="segments-monitor">
                        {/* Render more detailed information for flight(s) to destination */}
                        {details && renderMoreDetailedFlights(details.segments, details)}
                    </div>
                </div>
            </div>

            {detailsBack && <h2 className={"more-details-title"}>Flight Back Home</h2>}
            <div className="container-info segments-container" style={{height: `${outerBackHeight}px`, visibility: details ? 'visible' : 'hidden' }}>
                <div className="flight-info-inner">
                    <div ref={innerContainerBackRef} className="segments-monitor">
                        {/* Render detailsBack (Flight Back) */}
                        {detailsBack && renderMoreDetailedFlights(detailsBack.segments, detailsBack)}
                    </div>
                </div>
            </div>
        </div>
    );

};

export default FlightOfferMoreDetails;
