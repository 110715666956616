// actions/offerActions.js
export const UPDATE_OFFER_SELECTION = 'UPDATE_OFFER_SELECTION';
export const RESET_OFFERS = 'RESET_OFFERS';
export const SET_IMAGE_INDEX = 'SET_IMAGE_INDEX'; // Add a new action type
export const SET_HOTEL_ID = 'SET_HOTEL_ID'; // New action for hotel ID
export const UPDATE_OFFER_PAGE_INDEX = 'UPDATE_OFFER_PAGE_INDEX';
export const SET_ACCOMMODATION_DETAILS = "SET_ACCOMMODATION_DETAILS";
export const SET_FLIGHTS_SELECTED = "SET_FLIGHTS_SELECTED";
export const SET_ACCOMMODATION_SELECTED = "SET_ACCOMMODATION_SELECTED";

export const SAVE_OFFERS = 'SAVE_OFFERS';

export const updateOfferSelection = (itemId, optionId) => ({
    type: UPDATE_OFFER_SELECTION,
    payload: { itemId, optionId },
});

export const resetOffers = () => ({
    type: RESET_OFFERS,
});

export const setHotelId = (hotelId) => ({
    type: SET_HOTEL_ID,
    payload: { hotelId },
});

export const setImageIndex = (hotelId, index) => ({
    type: SET_IMAGE_INDEX,
    payload: { hotelId, index },
});

export const setFlightsSelected = (selected) => ({
    type: SET_FLIGHTS_SELECTED,
    payload: { selected },
});

export const setAccommodationSelected = (selected) => ({
    type: SET_ACCOMMODATION_SELECTED,
    payload: { selected },
});

export const saveOffers = (offers) => ({
    type: SAVE_OFFERS,
    payload: offers,
});

export const setAccommodationDetails = (details) => ({
    type: SET_ACCOMMODATION_DETAILS,
    payload: details,
});