import React, {useState} from "react";
import "./styles.css";
import "../../../commonStyles.css";

const NextButtonOffers = ({onClick, flightsSelected, accommodationSelected, step, text="Next"}) => { // Fix: Use { onClick }
    const [isPressed, setIsPressed] = useState(false);

    const handleClick = () => {
        setIsPressed(true);
        setTimeout(() => {
            setIsPressed(false);
        }, 300);
        if (onClick && step >= 3 ) {
            onClick();
        } else if (onClick && flightsSelected && accommodationSelected && step === 2) { // Ensure onClick is defined before calling
            onClick();
        } else if (onClick && flightsSelected && step === 1) {
            onClick();
        } else {

        }
    };

    return (
        <div
            className={`button-next-large-outer nav-button-outer rounded-right-button ready ${isPressed ? "pressed" : ""}`}
            onClick={handleClick}
        >
            <div className={`button-next-large-inner nav-button-inner rounded-right-button ready ${isPressed ? "translate" : ""}`}>
                {text}
            </div>
        </div>
    );
};

export default NextButtonOffers;
