import React from "react";
import "../styles.css";
import { ReactComponent as PowerIcon } from "../../../images/icons/offersPage/power.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateOfferSelection } from "../../../store/actions/offerActions";

const PowerButton = () => {
  const dispatch = useDispatch();

  const REDUX_INDEX_TRANSFER_TO_ACCOMMODATION = 2;
  const REDUX_INDEX_TRANSFER_FROM_ACCOMMODATION = 4;

  // Fetch Redux state
  const selectedOptionTransferToIndexFromRedux = useSelector(
    (state) => state.offer.selectedOffers?.[REDUX_INDEX_TRANSFER_TO_ACCOMMODATION]
  );

  const selectedOptionTransferFromIndexFromRedux = useSelector(
    (state) => state.offer.selectedOffers?.[REDUX_INDEX_TRANSFER_FROM_ACCOMMODATION]
  );

  // Check pressed state
  const isPressed = !(selectedOptionTransferToIndexFromRedux === 0 && selectedOptionTransferFromIndexFromRedux === 0);

  // Handle button click
  const handleClick = () => {
    if (isPressed) return; // Do nothing if already pressed
    // Update Redux state to mark button as pressed
    dispatch(updateOfferSelection(REDUX_INDEX_TRANSFER_TO_ACCOMMODATION, null)); // Example value
    dispatch(updateOfferSelection(REDUX_INDEX_TRANSFER_FROM_ACCOMMODATION, null)); // Example value
  };

  return (
    <div
      className={`button-power-outer ${isPressed ? "pressed" : ""}`}
      onClick={handleClick}
    >
      <div className={`button-power-inner ${isPressed ? "pressed" : ""}`}>
        <PowerIcon />
      </div>
    </div>
  );
};

export default PowerButton;
