import React, { useEffect } from 'react';
import './styles.css'; // Assuming you will create this CSS file for styling
import { ReactComponent as StarsIcon } from "../../../../../images/icons/star.svg";

const StarsDisplay = ({ numberOfStars }) => {
    useEffect(() => {
        // This code runs AFTER the component has rendered

        function setColor(id, newColor) {
            const element = document.getElementById(id); // Get element by ID
            if (element) {  // Important check!
                element.style.fill = newColor;
            }
        }

        // Use document.documentElement to access the root element
        const color = getComputedStyle(document.documentElement).getPropertyValue('--disabled-text').trim();

        // Loop through each star and set its color
        for (let i = 1; i <= numberOfStars; i++) {
            setColor(`star${i}`, color);
        }

    }, [numberOfStars]); // Re-run effect if numberOfStars changes

    return (
        <div className="stars-display">
            {Array.from({ length: numberOfStars }, (_, index) => (
                <StarsIcon key={index} id={`star${index + 1}`} /> // Assign unique IDs
            ))}
        </div>
    );
};

export default StarsDisplay;