import React from 'react';
import './styles.css';
import NextButtonOffers from "../tripOrganisation/buttons/nextButtonOffers";
import OfferSelectionMonitor from "../offersPage/buttons/offerSelectionMonitor";

const OffersNavigation = ({onNextClick, step, price, flightsSelected, accommodationSelected, nextButtonText}) => {

    return (
        <>
            <div className={"navigation-wrapper offer-width"}>
                <div className={"navigation-wrapper offer-width"}>
                    <OfferSelectionMonitor step={step} price={price}/>
                    <NextButtonOffers onClick={onNextClick} flightsSelected={flightsSelected}
                                      accommodationSelected={accommodationSelected} step={step} text={nextButtonText}/>
                </div>

            </div>
            <div className={"blank-space"}></div>
        </>
    )
        ;
};

export default OffersNavigation;