import React from 'react';
import PreviousButton from "../tripOrganisation/buttons/previousButton";
import NextButton from "../tripOrganisation/buttons/nextButton";
import './styles.css';
import CustomNextButton from "../tripOrganisation/buttons/customNextButton";

const Navigation = ({
                        onPreviousClick,
                        onNextClick,
                        nextDisabled = false,
                        firstButton = true,
                        secondButton = true,
                    }) => {

    const soloSecondButton = !firstButton && secondButton;
    const buttonText = "Get destinations";
    return (
        <>
            <div className={"navigation-wrapper"}>
                <div className="navigation-wrapper">
                    <div className="navigation">
                        {/* Use logical && for conditional rendering */}
                        {firstButton && <PreviousButton onClick={onPreviousClick}/>}
                        {secondButton && !soloSecondButton &&
                            <NextButton onClick={onNextClick} disabled={nextDisabled}/>}
                        {secondButton && soloSecondButton &&
                            <CustomNextButton onClick={onNextClick} text={buttonText} isReady={!nextDisabled}/>}
                    </div>
                </div>
            </div>
            <div className={"blank-space"}></div>
        </>
    );
};

export default Navigation;