import HotelOfferDetails from "./HotelOffer";
import React, {useEffect, useState} from "react";
import {countFirstLevelChildren} from "../../utils/dataProcessingFunctions";
import SelectionButton from "../buttons/selectionButton";
import {useDispatch, useSelector} from "react-redux";
import {
    setAccommodationSelected,
    setHotelId,
    updateOfferSelection
} from "../../../store/actions/offerActions";
import ImagesPage from "./HotelImagesPage";
import HotelAmenitiesPage from "./HotelAmenities";

import '../carousel/detailsPage/styles.css';
import OfferHeader from "../../header/offerHeader";
import './styles.css';

const AccommodationPage = () => {
    const offerInRedux = useSelector((state) => state.offer.offerInRedux);
    const accommodationDetails = offerInRedux.offers[2];

    const ACCOMMODATION_REDUX_INDEX = 3;
    const dispatch = useDispatch();
    const selectedOptionIndexFromRedux = useSelector((state) => {
        const offerDetails = state.offer.selectedOffers || {};
        return offerDetails[ACCOMMODATION_REDUX_INDEX];
    });

    const [selectedOptionIndex, setSelectedOptionIndex] = useState(selectedOptionIndexFromRedux);
    // Set the hotel ID in Redux when hotel details are available
    useEffect(() => {
        if (offerInRedux && offerInRedux.info1) {
            dispatch(setHotelId(selectedOptionIndex + 1));
        }
    }, [offerInRedux, selectedOptionIndex, dispatch]);

    const handleOptionClick = (index) => {
        setSelectedOptionIndex(null); // Set selectedOptionIndex to null temporarily
        setTimeout(() => {
            setSelectedOptionIndex(index);
            dispatch(updateOfferSelection(ACCOMMODATION_REDUX_INDEX, index));
            dispatch(setAccommodationSelected(true));
        }, 100);


    };

    const getClassName = (numberOfButtons, buttonOrderNumber) => {
        switch (numberOfButtons) {
            case 1:
                return buttonOrderNumber === 0 ? "single-button rounded-bottom-button" : "";
            case 2:
                return buttonOrderNumber === 0 ? "one-of-two left" : "one-of-two right";
            case 3:
                return buttonOrderNumber === 0 ? "left" :
                    buttonOrderNumber === 1 ? "central" : "right";
            default:
                return "";
        }
    };

    const renderOptions = (selectionBox) => {
        return Object.keys(selectionBox).map((key, index) => {
            const option = selectionBox[key];
            const isSelected = index === selectedOptionIndex;
            const buttonCount = countFirstLevelChildren(selectionBox);

            return (
                <SelectionButton
                    key={key}
                    price={option.price}
                    onClick={() => handleOptionClick(index)}
                    isSelected={isSelected}
                    className={getClassName(buttonCount, index)}
                />
            );
        });
    };

    return (
        <>
            <OfferHeader/>
            <HotelOfferDetails details={accommodationDetails.info1.detailsGroup1[selectedOptionIndex]}/>
            <div className="selection-options">{renderOptions(accommodationDetails.info1['selection-box'])}</div>
            <ImagesPage details={accommodationDetails.info1.detailsGroup1[selectedOptionIndex]}/>
            <HotelAmenitiesPage
                details={accommodationDetails.info1.detailsGroup1[selectedOptionIndex]}/>
        </>
    );

};
export default AccommodationPage;