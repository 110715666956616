import React, {useEffect, useState} from "react";
import PostRequest from "../../../api/postRequest";
import LoadingSpinner from "../../../utils/loadingSpinner/loadingSpinner";
import '../../../../commonStyles.css';
import '../styles.css';
import './styles.css';
import image1 from "../../../../images/sample1.jpeg";
import image2 from "../../../../images/sample2.jpeg";
import image3 from "../../../../images/sample3.jpeg";
import image4 from "../../../../images/sample4.jpeg";
import image5 from "../../../../images/sample5.jpeg";
import image6 from "../../../../images/sample6.jpeg";
import image7 from "../../../../images/barcelona1.jpeg";
import image8 from "../../../../images/barcelona2.jpeg";
import image9 from "../../../../images/rome1.jpg";
import image10 from "../../../../images/rome2.jpeg";
import ImagesCitiesSlider from "../../../utils/imagesSlider/ImagesCitiesSlider";
import GetRequest from "../../../api/getRequest";
import SearchAgainButton from "../../buttons/searchAgainButton";
import {useNavigate} from "react-router-dom";
import CityInformationPopup from "../cityInformation/cityInformation";

const MAX_CITIES_DISPLAYED = 15;
const MAX_ATTEMPTS_TO_FETCH = 10;
const RETRY_DELAY = 3000;

const imageSets = [
    [image1, image2],
    [image3, image4],
    [image5, image6],
    [image7, image8],
    [image9, image10]
];

function CitiesPickStep({nextStep, prevStep}) {
    const [cities, setCities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [dataFetched, setDataFetched] = useState(false);
    const [cityPopup, setCityPopup] = useState(false);
    const [selectedCity, setSelectedCity] = useState(null); // Store the selected city

    const [currentIndexes, setCurrentIndexes] = useState({}); // Track current index for each city

    const navigate = useNavigate();

    useEffect(() => {
        const fetchCitiesWithRetry = async (attempt = 0) => {
            if (attempt >= MAX_ATTEMPTS_TO_FETCH) {
                console.error("Failed to fetch cities after multiple attempts");
                setIsLoading(false);
                return;
            }

            console.log("Attempting to fetch cities, attempt:", attempt);
            try {
                const getData = GetRequest('api/get_destinations/');
                const data = await getData();
                console.log("data cities", data);
                if (data.cities && data.cities.length > 0) {
                    setCities(data.cities.slice(0, MAX_CITIES_DISPLAYED));
                    setIsLoading(false);
                    setDataFetched(true);

                    // Initialize currentIndexes for all cities
                    const initialIndexes = data.cities.slice(0, MAX_CITIES_DISPLAYED).reduce((acc, city, index) => {
                        acc[city] = 0; // Start with the first image for each city
                        return acc;
                    }, {});
                    setCurrentIndexes(initialIndexes);
                } else {
                    setTimeout(() => {
                        fetchCitiesWithRetry(attempt + 1);
                    }, RETRY_DELAY);
                }
            } catch (error) {
                console.error("Error fetching cities:", error);
                setTimeout(() => {
                    fetchCitiesWithRetry(attempt + 1);
                }, RETRY_DELAY);
            }
        };

        fetchCitiesWithRetry(); // Initial call with attempt 0
    }, []);

    const handleCityClick = async (city) => {
        setCityPopup(true);
        setSelectedCity(city);
    };

    // In SearchForm component
    const navigateToInitialPage = () => {
        // Reset form data and other relevant state variables

        // Navigate to the initial step
        navigate('/search/1');
    };

    return (
        <div className="form-inline-wrapper city-pick-length">
            {isLoading ? (
                <LoadingSpinner/>
            ) : dataFetched ? (
                <>
                    <h2 className="page-title">Select a destination</h2>
                    <p>to get the final offer</p>

                    <div className="cities-container">
                        {cities.map((city, index) => {
                            const imageSet = imageSets[index % imageSets.length];

                            return (
                                <div key={index} className="city-slider-container bottom-shadow">
                                    <ImagesCitiesSlider
                                        preloadedImages={imageSet}
                                        altAttribute={`Images of ${city}`}
                                        onClick={() => handleCityClick(city)}
                                        cityName={city}
                                    />
                                </div>

                            );
                        })}
                    </div>
                    {selectedCity && (
                        <CityInformationPopup
                            cityName={selectedCity}
                            onClose={() => setSelectedCity(null)}
                            isOpen={cityPopup}
                            onNextStep={() => nextStep()} // Pass selectedCity to nextStep
                        />
                    )}
                </>
            ) : (
                <div className="error-message">
                    <h3>Server Busy</h3>
                    <p>Please try again later. The server is currently busy or unavailable.</p>
                    <SearchAgainButton onReset={navigateToInitialPage}/>
                </div>
            )}
        </div>
    );
}

export default CitiesPickStep;
