import React, {useEffect, useRef} from "react";

import '../../../../commonStyles.css';
import '../styles.css';
import './styles.css';
import {useNavigate} from "react-router-dom";
import PostRequest from "../../../api/postRequest";
import CloseButton from "../../buttons/closeButton";
import CityPopupNavigation from "../../../footer/cityPopupNavigation";
import Cookies from "js-cookie";

function CityInformationPopup({cityName, cityData, onClose, onNextStep, isOpen}) {

    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    const wrapperRef = useRef(null);

    // Handler for clicks outside the popup
    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            onClose();
        }
    };


    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        // Cleanup the event listener on unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    const handleNextStepClick = async () => {
        try {
            await PostRequest({city: cityName}, 'api/set_cities/', 'json');
            console.log('Cities set successfully');
            console.log("setting cookie for city", cityName);
            Cookies.set('destination', cityName, {
                    sameSite: window.location.protocol === 'https:' ? 'None' : 'Lax',
                    secure: window.location.protocol === 'https:',
                    path: '/'
                });
            onNextStep();
        } catch (error) {
            console.error("Error setting cities:", error);
        }
    };

    return (
        <div className={`city-information-wrapper ${isOpen ? "open" : ""}`}>
            <>
                <div className={"city-information-content"} ref={wrapperRef}>
                    <div className={"city-info-header"}>
                        <div className={"city-info-title"}>
                            <h2 className="page-title">{cityName} information</h2>
                            <p>Information about the destination</p>
                        </div>
                        <div className={"x-button"}>
                                <CloseButton onClick={onClose}/>
                        </div>
                    </div>
                    <CityPopupNavigation
                        onNextClick={handleNextStepClick}
                        buttonText={`Go to ${cityName}`}
                    />
                </div>
            </>
        </div>
    );
}

export default CityInformationPopup;
