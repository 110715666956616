// src/api/getRequest.js
const GetRequest = async (path) => {  // Remove the extra function wrapper
    let requestUrl;

    if (process.env.REACT_APP_WORKMODE === 'dev') {
        requestUrl = `http://${process.env.REACT_APP_URL}:${process.env.REACT_APP_DJANGO_PORT}${path.startsWith('/') ? path : `/${path}`}`;
        console.log("request url", requestUrl);
    } else {
        requestUrl = `/${path.replace(/^\/+/, '')}`;
    }

    try {
        const response = await fetch(requestUrl, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error("Fetching data failed: ", error);
        throw error;
    }
};

export default GetRequest;
