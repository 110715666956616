import React, {useState, useEffect, useRef, forwardRef} from 'react';
import './styles.css';
import Cookies from "js-cookie";
import LocationImage from '../../../images/destinations/my-location-svgrepo-com.svg';
import XButtonImage from '../../../images/icons/x.svg';
import PostRequest from "../../api/postRequest";
import CustomNextButton from "../buttons/customNextButton";

const Autocomplete = forwardRef(({
                                     handleInputChange,
                                     values,
                                     setIsValidSelection,
                                     startingPoint,
                                     expanded,
                                     onNextClick
                                 }, ref) => {
    const initialInputValue = startingPoint || (values.departureLocation ? values.departureLocation.split(', ').pop() : '');
    const [inputValue, setInputValue] = useState(initialInputValue);
    const [results, setResults] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const [userHasInteracted, setUserHasInteracted] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const debounceTimeout = useRef(null);
    const autocompleteRef = useRef(null);


    const [location, setLocation] = useState(null);
    const [error, setError] = useState(null);

    const preFilledPlaceholder = "Starting Point";

    const MAX_NUMBER_OF_RESULTS = 10;

    useEffect(() => {
        if (inputValue.length >= 2 && dropdownVisible) {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
            debounceTimeout.current = setTimeout(() => {
                let path;
                if (process.env.REACT_APP_WORKMODE === 'dev') {
                    path = `http://${process.env.REACT_APP_URL}:${process.env.REACT_APP_DJANGO_PORT}/api/autocomplete_airports/?input=${inputValue}`;
                } else {
                    path = `/api/autocomplete_airports/?input=${inputValue}`;
                }
                fetch(path)
                    .then(response => response.json())
                    .then(data => {
                        const formattedResults = data.message.map(item => ({
                            city: item.city,   // Access city correctly
                            country: item.country, // Access country correctly
                            admin_name: item.admin_name,
                            id: item.id
                        }));
                        setResults(formattedResults.slice(0, MAX_NUMBER_OF_RESULTS));
                        setDropdownVisible(true); // Show the dropdown after fetching results
                    })
                    .catch(error => console.error('Error fetching data:', error));
            }, 50);
        } else {
            setResults([]);
            setDropdownVisible(false); // Hide the dropdown if input is less than 2 characters
        }
    }, [inputValue]);


    useEffect(() => {
        console.log("location sending", location);
        if (location) {
            // Convert to proper JSON format
            const locationData = {
                latitude: location.latitude,
                longitude: location.longitude,
                timestamp: new Date().toISOString()  // Optional: add timestamp
            };
            sendCoordinates(locationData);
        }
    }, [location]);

    useEffect(() => {
        setInputValue(startingPoint || ''); // Update input when startingPoint changes
    }, [startingPoint]);

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (err) => {
                    setError(err.message);
                }
            );
        } else {
            setError('Geolocation is not supported by this browser.');
        }
    };

    const clearInput = () => {
        setInputValue(''); // Clear the input value
        setResults([]); // Clear the autocomplete results
        setDropdownVisible(false); // Hide the dropdown
        setIsValidSelection(false); // Mark the selection as invalid
        handleInputChange('departureLocation')({target: {value: ''}}); // Update the parent component
        setUserHasInteracted(true); // Mark that the user has interacted
    };

    const sendCoordinates = async (locationData) => {
        try {
            const path = 'api/set_geolocation/';

            const readyToSend = `(${locationData.latitude},${locationData.longitude})`

            const response = await PostRequest(readyToSend, path, 'json');

            if (response === "geolocation set!") {
                console.log("geolocation set successfully");
            } else {
                console.error("Unexpected response:", response);
            }
        } catch (error) {
            console.error("Error sending location:", error);
        }
    };

    const onInputChange = (e) => {
        const {value} = e.target;
        setInputValue(value);
        console.log("value for autocomplete", value);
        handleInputChange('departureLocation')({target: {value}});
        setIsValidSelection(false);
        setDropdownVisible(true); // Show the dropdown when typing
        if (!userHasInteracted) setUserHasInteracted(true);
    };

    const onItemClick = (item) => {
        // Set input value in the format: City (Code)
        const startingPoint = `${item.city}, ${item.country}, ${item.admin_name}`;
        setInputValue(startingPoint);

        // Update the parent component with the selected airport code
        handleInputChange('departureLocation')({target: {value: startingPoint}});

        // Mark the selection as valid and remove any previous error messages
        setIsValidSelection(true);
        setUserHasInteracted(true);

        Cookies.set('startingPoint', startingPoint, {
            sameSite: window.location.protocol === 'https:' ? 'None' : 'Lax',
            secure: window.location.protocol === 'https:',
            path: '/'
        });

        // Clear the autocomplete results and hide the dropdown
        setResults([]);
        setDropdownVisible(false);
    };

    const handleNextClick = () => {

        // Call the parent's onNextClick if it exists
        if (onNextClick) {
            onNextClick();
        }
    };

    // Determine if the location block should be hidden
    const shouldRemoveLocationBlock = inputValue.length >= 2;

    return (
        <div className={`autocomplete rounded-button bottom-shadow ${expanded ? "expanded" : ""}`}
             ref={autocompleteRef}>
            <div className={`${expanded ? "expanded" : ""}`}>
                <div className={`inner-block ${expanded ? "expanded" : ""}`}>
                    {expanded && (<p className={"input-box-title"}>Where from? </p>)}
                    <div className={`destination-input-field rounded-left ${expanded ? "" : "collapsed"}`}>
                        {!isFocused && !inputValue &&
                            <div
                                className={`placeholder-text ${expanded ? "" : "placeholder-collapsed"}`}>{preFilledPlaceholder}</div>}
                        {!expanded && <p className={"disabled-text"}> From</p>}
                        <input
                            type="text"
                            value={inputValue}
                            onChange={onInputChange}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            required
                            className={` ${inputValue ? '' : 'placeholder-active'} ${expanded ? "" : "input-collapsed"}`}
                            ref={ref}
                        />
                        {/* Conditionally render the location block */}
                        {!shouldRemoveLocationBlock && expanded && (
                            <div
                                onClick={getLocation}
                                className={"location-block rounded-right-button"}
                            >
                                <img src={LocationImage} alt="Location"/>
                            </div>
                        )}
                        {shouldRemoveLocationBlock && expanded && (
                            <div
                                onClick={clearInput}
                                className={"location-block rounded-right-button"}
                            >
                                <img src={XButtonImage} alt="Clear Input"/>
                            </div>
                        )}
                    </div>
                    {dropdownVisible && results.length > 0 && (
                        <ul className="autocomplete-results">
                            {console.log("rezulttlatlta", results)}
                            {results.map((result, index) => (
                                <li
                                    key={index}
                                    className={`autocomplete-item ${index === results.length - 1 ? 'last' : ''} ${index === 0 ? 'first' : ''}`}
                                    onClick={() => onItemClick(result)}
                                >
                                    <span className="name">{result.city}, {result.country}, {result.admin_name}</span>
                                </li>
                            ))}
                        </ul>
                    )}
                    {expanded && (<div className={"empty-space"}></div>)}

                    {expanded && (
                        <div className={"input-navigation"}>
                            <CustomNextButton onClick={handleNextClick} isReady={true}/>
                        </div>)}
                </div>
            </div>
        </div>
    );
});

export default Autocomplete;