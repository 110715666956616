import React, {useState, useEffect, useMemo, useRef} from "react";

import "./styles.css";
import DatepickerDayButton from "../buttons/keyboardButton";
import DatepickerDoneButton from "../buttons/datepickerDoneButton";
import {useDispatch, useSelector} from "react-redux";
import {
    resetEndDate,
    resetStartDate, setEndDateRedux,
    setStartDateRedux,
} from "../../../store/actions/tripOrganisationActions";
import {formatDisplayDate} from "../../utils/dataProcessingFunctions";

const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December",
];

const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

const CustomCalendar = ({isOpen, onClose, selectedRange}) => {
    const [tempStartDate, setTempStartDate] = useState(null);
    const [tempEndDate, setTempEndDate] = useState(null);
    const [error, setError] = useState(null);
    const [displayedMonths, setDisplayedMonths] = useState([new Date()]); // Array of displayed months
    const minDateFromRedux = useSelector((state) => state.tripOrganisation.minDateSet);
    const startDateFromRedux = useSelector((state) => state.tripOrganisation.startDate);

    const scrollableRef = useRef(null);  // Add this ref
    const dispatch = useDispatch();


    useEffect(() => {
        // Initialize displayedMonths with the current month and the next 5 months
        const initialMonths = [];
        const current = new Date();
        for (let i = 0; i < 12; i++) {
            const newMonth = new Date(current.getFullYear(), current.getMonth() + i, 1);
            if (hasSelectableDaysInCurrentMonth(current)) {
            }
            initialMonths.push(newMonth);
        }
        setDisplayedMonths(initialMonths);
    }, []);


    useEffect(() => {
        if (selectedRange) {
            setTempStartDate(selectedRange.start);
            setTempEndDate(selectedRange.end);
        } else {
            setTempStartDate(startDateFromRedux || null);
            setTempEndDate(null);
        }
    }, [selectedRange]);

    // Add this useEffect to handle scrolling when calendar opens
    useEffect(() => {
        if (isOpen && scrollableRef.current) {
            // Small timeout to ensure DOM is rendered
            setTimeout(() => {
                scrollToFirstSelectableDay();
            }, 50);
        }
    }, [isOpen]);

    // Add this function to find and scroll to first selectable day
    const scrollToFirstSelectableDay = () => {
        if (!scrollableRef.current) return;

        const today = new Date();
        const minSelectableDate = new Date(today.setDate(today.getDate() + 7));

        // Find the first month that contains selectable days
        const firstSelectableMonthIndex = displayedMonths.findIndex(monthDate => {
            return monthDate >= minSelectableDate ||
                hasSelectableDaysInCurrentMonth(monthDate);
        });

        if (firstSelectableMonthIndex >= 0) {
            const monthContainers = scrollableRef.current.querySelectorAll('.month-container');
            if (monthContainers[firstSelectableMonthIndex]) {
                monthContainers[firstSelectableMonthIndex].scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }

        }
    }

    // Update the hasSelectableDaysInCurrentMonth function to be more accurate
    const hasSelectableDaysInCurrentMonth = (currentDate) => {
        const today = new Date();
        const minSelectableDate = new Date(today.setDate(today.getDate() + 7));

        // Check if this month is after the minimum selectable date
        if (new Date(currentDate.getFullYear(), currentDate.getMonth(), 1) >= minSelectableDate) {
            return true;
        }

        // Check individual days if month contains the threshold
        const daysInMonth = getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());
        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), daysInMonth);

        return lastDay >= minSelectableDate && firstDay <= minSelectableDate;
    }

const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

const getNumberOfRows = (currentDate) => {
    const daysInMonth = getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    return Math.ceil((firstDayOfMonth + daysInMonth) / 7);
};

const handleDayClick = (day, currentDate) => {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    setError(null);

    if (!tempStartDate) {
        setTempStartDate(date);
        dispatch(setStartDateRedux(date));
    } else if (!tempEndDate) {
        // Check if the selected end date is before the start date
        if (date < tempStartDate) {
            // If it is, swap the start and end dates
            setTempEndDate(tempStartDate);
            setTempStartDate(date);
            dispatch(setStartDateRedux(date)); // Update Redux if needed
            dispatch(setEndDateRedux(tempStartDate));
        } else {
            setTempEndDate(date);
            dispatch(setEndDateRedux(date));
        }
    } else {
        dispatch(setStartDateRedux(date));
        dispatch(resetEndDate());
        setTempStartDate(date);
        setTempEndDate(null);
    }
};

const isDayActive = (day, currentDate) => {
    if (!tempStartDate) return false;
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    return (
        date.getDate() === tempStartDate.getDate() &&
        date.getMonth() === tempStartDate.getMonth() &&
        date.getFullYear() === tempStartDate.getFullYear()
    );
};

const isDaySelectable = (day, currentDate) => {
    const today = new Date();
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    return date > new Date(today.setDate(today.getDate() + 7));
};

const isDayInRange = (day, currentDate) => {
    if (!tempStartDate || !tempEndDate) return false;
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    return date >= Math.min(tempStartDate, tempEndDate) && date <= Math.max(tempStartDate, tempEndDate); // Corrected range check
};



// Determine the button label based on the state
const getButtonLabel = () => {
    if (!tempStartDate && !tempEndDate) {
        return "Select Start Date";
    } else if (tempStartDate && !tempEndDate) {
        return "Select End Date";
    } else {
        return "Done";
    }
};

const renderDays = useMemo(() => {
    return displayedMonths.map((currentDate, index) => {
        const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
        const days = [];

        // Check if the current month is the last month in the displayedMonths array
        const isLastMonth = index === displayedMonths.length - 1;

        for (let i = 0; i < firstDayOfMonth; i++) {
            days.push(<div key={`empty-${i}-${currentDate.getMonth()}`} className="datepicker-day-empty"/>);
        }

        for (let i = 1; i <= daysInMonth; i++) {
            const isDisabled = !isDaySelectable(i, currentDate);
            days.push(
                <DatepickerDayButton
                    key={`${i}-${currentDate.getMonth()}`}
                    day={i}
                    isActive={isDayActive(i, currentDate)}
                    isInRange={isDayInRange(i, currentDate)}
                    isStart={
                        i === tempStartDate?.getDate() &&
                        tempStartDate?.getMonth() === currentDate.getMonth() &&
                        tempStartDate?.getFullYear() === currentDate.getFullYear()
                    }
                    isEnd={
                        i === tempEndDate?.getDate() &&
                        tempEndDate?.getMonth() === currentDate.getMonth() &&
                        tempEndDate?.getFullYear() === currentDate.getFullYear()
                    }
                    onClick={() => handleDayClick(i, currentDate)}
                    isDisabled={isDisabled}
                />
            );
        }

        while (days.length % 7 !== 0) {
            days.push(<div key={`extra-${days.length}-${currentDate.getMonth()}`}
                           className="datepicker-day-empty"/>);
        }
        return (
            <div key={currentDate.getMonth()} className={`month-container ${isLastMonth ? "last-month" : ""} `}>
                <div className="calendar-header-inner">
                    {months[currentDate.getMonth()]}&nbsp;{currentDate.getFullYear()}
                </div>
                <div
                    className={`calendar-days ${getNumberOfRows(currentDate) === 5 ? "tight-rows" : ""} ${getNumberOfRows(currentDate) === 4 ? "very-tight-rows" : ""}`}>
                    {days}
                </div>
            </div>
        );
    });
}, [displayedMonths, tempStartDate, tempEndDate]);



// Usage in your JSX:
const dateDisplay = formatDisplayDate(tempStartDate, tempEndDate);

const handleDoneClick = () => onClose();

return (
    <>
        <div className="time-range-container rounded-button bottom-shadow"
        >
            {!isOpen && (
                <div className="time-range-box rounded-button">
                    {<div className={"disabled-text rounded-left-button"}> When</div>}
                    <div className={"date-range-info-wrapper"}>
                        <div className="date-range-info rounded-right-button">
                            <div className={`range-display ${tempStartDate ? "" : "unselected"}`}>
                                {dateDisplay.start}
                            </div>
                            <div className={"line unselected"}>-</div>
                            <div className={`range-display ${tempEndDate ? "" : "unselected"}`}>
                                {dateDisplay.end}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isOpen && (
                <>
                    <div className={"input-box-title"}> When's your trip?</div>
                    <div>
                        <div className={`custom-calendar rounded-button`}>
                            <div className="datepicker-day-names">
                                {dayNames.map((dayName, index) => (
                                    <div key={index} className="datepicker-day-name">
                                        {dayName}
                                    </div>
                                ))}
                            </div>
                            <div className={"calendar-open"}>

                                <div className={"scrollable-wrapper"} ref={scrollableRef}>

                                    <div
                                        className={"months-container"}
                                    >
                                        {renderDays}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={"datepicker-navigation rounded-bottom-button"}>
                            <DatepickerDoneButton onClick={handleDoneClick} label={getButtonLabel()}/>
                        </div>
                    </div>
                </>
            )
            }
        </div>
    </>
)
    ;
}
;

export default CustomCalendar;
