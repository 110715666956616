import React, { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import '../../../commonStyles.css';

function LoadingSpinner() {
    const [colorSpinner, setColorSpinner] = useState('');

    // Use useEffect to retrieve the CSS variable value when the component mounts
    useEffect(() => {
        // Get the root element (where CSS variables are defined)
        const root = document.documentElement;

        // Use getComputedStyle to retrieve the value of --disabled-text
        const disabledTextColor = getComputedStyle(root).getPropertyValue('--disabled-text').trim();

        // Set the colorSpinner state with the retrieved value
        setColorSpinner(disabledTextColor);
    }, []);

    return (
        <div className="spinner-container">
            <TailSpin
                type="TailSpin"
                color={colorSpinner} // Use the retrieved color
                height={200}
                width={200}
                timeout={10000} // 10 secs
            />
        </div>
    );
}

export default LoadingSpinner;