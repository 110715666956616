import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {setFlightsSelected, updateOfferSelection} from '../../../store/actions/offerActions';
import '../carousel/overviewPage/styles.css';
import {countFirstLevelChildren, getRequestParamsFromCookies} from "../../utils/dataProcessingFunctions";
import SelectionButton from "../buttons/selectionButton";
import FlightOffer from "./FlightOffer";
import FlightOfferMoreDetails from "./FlightMoreDetails";
import {splitPriceAndCurrency} from "../../utils/calculationFunctions";
import OfferHeader from "../../header/offerHeader";

const FlightsPage = ({details}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let selectedOptionIndexFromRedux = useSelector((state) => {
        const offerDetails = state.offer.selectedOffers || {};
        return offerDetails[1];
    });


    const [selectedOptionIndex, setSelectedOptionIndex] = useState(selectedOptionIndexFromRedux ?? 0);

    useEffect(() => {
        setSelectedOptionIndex(selectedOptionIndexFromRedux); // Update with 0 if redux value is null
    }, [selectedOptionIndexFromRedux]);

    const getSelectedDetails = (group) => {
        if (
            details &&
            details.info1 &&
            details.info1[group] && // Dynamic group access
            Array.isArray(details.info1[group]) &&
            selectedOptionIndex >= 0 &&
            selectedOptionIndex < details.info1[group].length
        ) {
            return details.info1[group][selectedOptionIndex];
        }
        return null;
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    useEffect(() => {
        setSelectedOptionIndex(selectedOptionIndexFromRedux); // Update with 0 if redux value is null
    }, [selectedOptionIndexFromRedux]);

    const handleOptionClick = (index) => {
        setSelectedOptionIndex(null); // Set selectedOptionIndex to null temporarily

        setTimeout(() => {
            setSelectedOptionIndex(index);
            dispatch(updateOfferSelection(1, index));
            dispatch(updateOfferSelection(5, index));
            dispatch(setFlightsSelected(true));
        }, 100);
    };

    const getClassName = (numberOfButtons, buttonOrderNumber) => {
        switch (numberOfButtons) {
            case 1:
                return buttonOrderNumber === 0 ? "single-button rounded-bottom-button" : "";
            case 2:
                return buttonOrderNumber === 0 ? "one-of-two left" : "one-of-two right";
            case 3:
                return buttonOrderNumber === 0 ? "left" :
                    buttonOrderNumber === 1 ? "central" : "right";
            default:
                return "";
        }
    };

    const renderOptions = (selectionBox, selectionBox2) => {
        if (!selectionBox || !selectionBox2) return null;

        return Object.keys(selectionBox).map((key, index) => {
            const option = selectionBox[key];
            const option2 = selectionBox2[key];
            const buttonCount = countFirstLevelChildren(selectionBox);
            const departurePriceArray = splitPriceAndCurrency(option?.price);
            const returnPriceArray = splitPriceAndCurrency(option2?.price);
            const currencyConsistent = departurePriceArray?.[1] === returnPriceArray?.[1];
            const totalPrice = (Number(departurePriceArray?.[0] || 0) + Number(returnPriceArray?.[0] || 0)).toFixed(2);
            let finalPrice;
            if (currencyConsistent) {
                finalPrice = totalPrice + departurePriceArray[1];
            } else {
                finalPrice = 'NaN';
                console.log("currency mismatch issue");
            }

            const isSelected = index === selectedOptionIndex; // Now correctly reflects selected state

            return (
                <SelectionButton
                    key={key}
                    price={finalPrice}
                    onClick={() => handleOptionClick(index)}
                    isSelected={isSelected}
                    className={getClassName(buttonCount, index)}
                />
            );
        });
    };

    const selectedDetails1 = getSelectedDetails('detailsGroup1');
    const selectedDetails2 = getSelectedDetails('detailsGroup2');

    return (
        <div className="details-wrapper">
            <OfferHeader />
            <div className="overlay-content">
                {( // Conditionally render FlightOffer
                    <FlightOffer
                        details={selectedDetails1}
                        detailsBack={selectedDetails2}
                        totalDuration={selectedDetails1?.totalDuration} // Optional chaining
                        totalDurationBack={selectedDetails2?.totalDuration} // Optional chaining
                    />
                )}

                <div className="selection-options">
                    {renderOptions(details?.info1?.['selection-box'], details?.info1?.['selection-box-2'])} {/* Optional chaining */}
                </div>

                <div className={"more-details-container"}>
                    {( // Conditionally render FlightOfferMoreDetails
                        <FlightOfferMoreDetails
                            details={selectedDetails1}
                            detailsBack={selectedDetails2}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default FlightsPage;