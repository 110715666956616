// src/store/reducers/transferReducer.js

import {
    SET_TRANSFER_FETCHED,
    RESET_TRANSFER_FETCHED,
    SET_TRANSFER_DATA,
    TOGGLE_POWER_BUTTON, SET_TRANSFER_SELECTED, SET_TRANSFER_AVAILABLE
} from '../actions/transferActions';

const initialState = {
    transferFetched: false,
    transferSelected: false,
    transferAvailable: true,
};

const transferReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TRANSFER_FETCHED:
            return {
                ...state,
                transferFetched: action.payload,
            };
        case RESET_TRANSFER_FETCHED:
            return {
                ...state,
                transferFetched: false,
            };
        case SET_TRANSFER_SELECTED:
            return {
                ...state,
                transferSelected: action.payload,
            };
        case SET_TRANSFER_AVAILABLE:
            return {
                ...state,
                transferAvailable: action.payload,
            };
        case SET_TRANSFER_DATA:
            return {
                ...state,
                data: action.payload,
            };
        case TOGGLE_POWER_BUTTON:
            return {
                ...state,
                isPowerButtonPressed: action.payload,
            };
        default:
            return state;
    }
};

export default transferReducer;
