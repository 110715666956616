import {formatCurrency} from "./calculationFunctions";
import Cookies from "js-cookie";

/**
 * Function to safely extract hotel details from the hotelInfo object.
 * @param {Object} hotelInfo - real data object containing hotels data.
 * @param {Object} currentOfferDetailsHotel - object where extracted data is stored
 * @param {number} optionIndex - The index of the specific hotel offer, can be 1,2 and 3
 *
 * @returns {Object|null} - The extracted hotel details or null if not found.
 */
function processAccommodationInfo(hotelInfo, optionIndex, currentOfferDetailsHotel) {
    try {
        let totalPrice = hotelInfo.dynamic_data.payment_options.payment_types[0].amount;
        let currency = hotelInfo.dynamic_data.payment_options.payment_types[0].currency_code;
        let hotelName = hotelInfo.static_data.name;
        let checkInTime = hotelInfo.static_data.check_in_time;
        let checkOutTime = hotelInfo.static_data.check_out_time;
        let starRating = hotelInfo.static_data.star_rating;
        let amenityGroups = hotelInfo.static_data.amenity_groups;
        let hotelImages = hotelInfo.static_data.images;
        let roomInfo = hotelInfo.dynamic_data.room_data_trans.main_name;

        // Ensure the structure for optionIndex exists in selection-box and details
        currentOfferDetailsHotel.info1["selection-box"][`option${optionIndex}`] = currentOfferDetailsHotel.info1["selection-box"][`option${optionIndex}`] || {};
        currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1] = currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1] || {segments: [{}]};

        currentOfferDetailsHotel.info1["selection-box"][`option${optionIndex}`].price = `${totalPrice}${formatCurrency(currency)}`;
        currentOfferDetailsHotel.info1["selection-box"][`option${optionIndex}`].name = hotelName;
        currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1].segments[0].name = hotelName;
        currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1].segments[0].checkInTime = checkInTime;
        currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1].segments[0].checkOutTime = checkOutTime;
        currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1].segments[0].stars = starRating;
        currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1].segments[0].amenity_groups = amenityGroups;
        currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1].images = hotelImages;
        currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1].segments[0].roomInfo = roomInfo;

    } catch (error) {
        console.log(`No hotel ${optionIndex} info`);
        console.error(error);
    }
    return currentOfferDetailsHotel;
}

// Function to process flight to destination data
const processFlightToDestination = (offerData, selectionBox, detailsGroup) => {
    if (!offerData || !Array.isArray(offerData)) {
        console.log("No real offer details available for departure");
        return;
    }

    updateSelectionBox(selectionBox, offerData);
    updateDetailsGroup(detailsGroup, offerData);
};


// Function to process return flight details
const processFlightFromDestination = (offerData, selectionBox, detailsGroup) => {
    if (!offerData || !Array.isArray(offerData)) {
        console.log("No real offer details available for return");
        return;
    }

    offerData.forEach((offer, index) => {
        const option = `option${index + 1}`;
        if (offer && offer[0][0]["oneWay"]) {
            const oneWayOffer = offer[0][1];
            selectionBox[option] = {
                price: `${oneWayOffer["price"]["grandTotal"]}${formatCurrency(oneWayOffer["price"]["currency"])}`,
                time: oneWayOffer.itineraries[0].segments[0].departure.at,
            };
            detailsGroup[index] = {
                totalDuration: oneWayOffer.itineraries[0].duration,
                segments: oneWayOffer.itineraries[0].segments,
                travelerInfo: {
                    price: oneWayOffer.price,
                    pricingOptions: oneWayOffer.pricingOptions,
                    travelerPricings: oneWayOffer.travelerPricings,
                }
            };
        } else if (offer) {
            const roundTripOffer = offer[0][0];
            selectionBox[option] = {
                price: `0${formatCurrency(roundTripOffer["price"]["currency"])}`,
                time: roundTripOffer.itineraries[1].segments[0].departure.at,
            };
            detailsGroup[index] = {
                totalDuration: roundTripOffer.itineraries[1].duration,
                segments: roundTripOffer.itineraries[1].segments,
                travelerInfo: {
                    price: roundTripOffer.price,
                    pricingOptions: roundTripOffer.pricingOptions,
                    travelerPricings: roundTripOffer.travelerPricings,
                }
            };
        }
    });
};


const processTransfer = (transferData, transferDetails, index, transferTypeKey, optionKey) => {
    if (!transferData || !transferData.message[index] || transferData.message === "No transfers found") {
        transferDetails.info1["selection-box"][optionKey] = {price: 0, time: 0};
        transferDetails.info1.detailsGroup1[0] = {
            segments: [{
                start: {dateTime: 0, locationCode: 0},
                end: {dateTime: 0},
                transferType: 0
            }]
        };
    } else {
        const transfer = transferData.message[index][transferTypeKey];
        transferDetails.info1["selection-box"][optionKey] = {
            price: `${transfer.converted.monetaryAmount}${formatCurrency(transfer.converted.currencyCode)}`,
            time: transfer.start.dateTime
        };
        transferDetails.info1.detailsGroup1[0] = {
            segments: [{
                start: {
                    dateTime: transfer.start.dateTime,
                    locationCode: transfer.start?.locationCode,
                    address: transfer.start?.address
                },
                end: {
                    dateTime: transfer.end?.dateTime,
                    locationCode: transfer.end?.locationCode,
                    address: transfer.end?.address
                },
                transferType: transfer.transferType,
                vehicle: transfer.vehicle
            }]
        };
    }
};

// Usage for both accommodations
const processTransferToAccommodation = (transferData, transferDetails, index) => {
    processTransfer(transferData, transferDetails, index, 'transfer_to_hotel', 'option1');
};

const processTransferFromAccommodation = (transferData, transferDetails, index) => {
    processTransfer(transferData, transferDetails, index, 'transfer_to_origin', 'option1');
};


/**
 * Function to get cookies set after first step in order to use data on offers page
 * **/
const getRequestParamsFromCookies = () => {
    // Retrieve the cookie by its name, which stores JSON string
    const params = Cookies.get('request_params');
    // Check if the params exist and are not undefined
    if (params) {
        try {
            // Parse the JSON string back into an object
            const paramsObj = JSON.parse(params);
            // Return the departureDate or any other required value
            return paramsObj
        } catch (error) {
            console.error("Error parsing JSON from cookies", error);
            return null;  // Return null or default value in case of error
        }
    }
    return null; // Return null if the cookie doesn't exist
};


function countFirstLevelChildren(json) {
    if (typeof json === 'object' && json !== null) {
        return Object.keys(json).length; // Returns the number of direct children (first level)
    }
    return 0; // Return 0 if it's not a valid object
}

const isOfferValid = (currentRealOfferDetails) => {
    if (!currentRealOfferDetails) {
        console.log("offer is null or missing", currentRealOfferDetails);
        return false;
    }
    let offerData;
    try {
        offerData = currentRealOfferDetails.offers.data;

        // Check if all offers have length === 0
        const allOffersEmpty = offerData.every(offer => offer[1].length === 0);

        if (allOffersEmpty) {
            return false;
        }
    } catch (error) {
        console.log("error while checking data validity ", error);
    }
    // Additional check for "no hotels found on the location"
    if (offerData === "no hotels found on the location") {
        return false;
    }
    return true;
};

export const createOffer = (id, selectionBoxOptions = {}, selectionBoxOptions2 = {}, detailsGroup1 = [], detailsGroup2 = []) => ({
    id,
    info1: {
        "selection-box": {...selectionBoxOptions},
        "selection-box-2": {...selectionBoxOptions2},
        detailsGroup1,
        detailsGroup2,
    }
});

const generateOffer = () => {
    // Shared selection box options for the 1st and 5th items
    const sharedSelectionBoxOptions = {
        option1: {price: '', time: ''},
        option2: {price: '', time: ''},
        option3: {price: '', time: ''},
    };

    // Details for the first and fifth items, split into two groups
    const detailsGroup1 = [
        {segments: [], totalDuration: ''},
        {segments: [], totalDuration: ''},
        {segments: [], totalDuration: ''},
    ];

    const detailsGroup2 = [
        {segments: [], totalDuration: ''},
        {segments: [], totalDuration: ''},
        {segments: [], totalDuration: ''},
    ];

    return {
        destination: `Sample Destination`,
        offers: [
            // 1st item with details split into two groups
            createOffer(1, {...sharedSelectionBoxOptions}, {...sharedSelectionBoxOptions}, detailsGroup1, detailsGroup2),

            // 2nd item
            createOffer(2, {
                option1: {price: '', time: ''}
            }, {}, [
                {
                    segments: [{
                        start: {dateTime: "", locationCode: ""},
                        end: {dateTime: ""},
                        transferType: "",
                        vehicle: {
                            code: "",
                            category: "",
                            duration: "",
                            seats: [{count: ""}],
                            baggages: [{count: "", size: ""}],
                            imageURL: ""
                        }
                    }]
                }
            ]),

            // 3rd item
            createOffer(3),

            // 4th item
            createOffer(4, {
                option1: {price: '', time: ''}
            }, {}, [
                {
                    segments: [{
                        start: {dateTime: "", locationCode: ""},
                        end: {dateTime: ""},
                        vehicle: {
                            code: "",
                            category: "",
                            duration: "",
                            seats: [{count: ""}],
                            baggages: [{count: "", size: ""}],
                            imageURL: ""
                        }
                    }]
                }
            ]),
        ]
    };
};

const updateSelectionBox = (selectionBox, offerData) => {
    offerData.forEach((offer, index) => {
        const realOfferFlightInfo = offer[0][0];
        const option = `option${index + 1}`;
        selectionBox[option] = {
            price: `${realOfferFlightInfo["price"]["grandTotal"]}${formatCurrency(realOfferFlightInfo["price"]["currency"])}`,
            time: realOfferFlightInfo.itineraries[0].segments[0].departure.at,
        };
    });
};

const updateDetailsGroup = (detailsGroup, offerData) => {
    offerData.forEach((offer, index) => {
        const realOfferFlightInfo = offer[0][0];
        detailsGroup[index] = {
            totalDuration: realOfferFlightInfo.itineraries[0].duration,
            segments: realOfferFlightInfo.itineraries[0].segments,
            travelerInfo: {
                price: realOfferFlightInfo.price,
                pricingOptions: realOfferFlightInfo.pricingOptions,
                travelerPricings: realOfferFlightInfo.travelerPricings,
            }
        };
    });
};

const getDestinationAirport = (offerData) => {
    const segments = offerData.offers[0].info1.detailsGroup1[0].segments;

    // Ensure segments array exists and has at least one element
    if (segments && segments.length > 0) {
        const lastSegment = segments[segments.length - 1]; // Get the last segment
        const iataCode = lastSegment.arrival.iataCode; // Access the IATA code
        return iataCode;
    } else {
        console.error("No segments found");
        return null; // Or handle the absence of segments as needed
    }

}

const formatDisplayDate = (startDate, endDate) => {
    if (!startDate) return {start: "start", end: "end"};

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const formatSingleDate = (date) => {
        return `${date.getDate().toString().padStart(2, "0")} ${months[date.getMonth()]}`;
    };

    const startFormatted = formatSingleDate(startDate);

    if (!endDate) {
        return {
            start: startFormatted,
            end: "end"
        };
    }

    const endFormatted = formatSingleDate(endDate);

    // Same month and year: "12-15 Mar"
    if (startDate.getMonth() === endDate.getMonth() &&
        startDate.getFullYear() === endDate.getFullYear()) {
        return {
            start: `${startDate.getDate().toString().padStart(2, "0")}`,
            end: `${endDate.getDate().toString().padStart(2, "0")} ${months[endDate.getMonth()]}`
        };
    }

    // Same year only: "12 Mar - 23 Apr"
    if (startDate.getFullYear() === endDate.getFullYear()) {
        return {
            start: startFormatted,
            end: endFormatted
        };
    }

    // Different years: "12 Mar '23 - 15 Jan '24"
    return {
        start: `${startFormatted} '${startDate.getFullYear().toString().slice(-2)}`,
        end: `${endFormatted} '${endDate.getFullYear().toString().slice(-2)}`
    };
};

export {
    processAccommodationInfo,
    getRequestParamsFromCookies,
    processFlightFromDestination,
    processFlightToDestination,
    countFirstLevelChildren,
    processTransferToAccommodation,
    processTransferFromAccommodation,
    isOfferValid,
    generateOffer,
    formatDisplayDate,
    getDestinationAirport
};