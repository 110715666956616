export const SET_TRANSFER_FETCHED = 'SET_TRANSFER_FETCHED';
export const SET_TRANSFER_SELECTED = 'SET_TRANSFER_SELECTED';
export const SET_TRANSFER_AVAILABLE = 'SET_TRANSFER_AVAILABLE';
export const RESET_TRANSFER_FETCHED = 'RESET_TRANSFER_FETCHED';
export const SET_TRANSFER_DATA = 'SET_TRANSFER_DATA';
export const TOGGLE_POWER_BUTTON = 'TOGGLE_POWER_BUTTON';
export const setTransferFetchedRedux = (fetched) => ({
    type: SET_TRANSFER_FETCHED,
    payload: fetched
});

export const resetTransferFetched = () => ({
    type: RESET_TRANSFER_FETCHED
});

export const setTransferSelectedRedux = (selected) => ({
    type: SET_TRANSFER_SELECTED,
    payload: selected
});

export const setTransferAvailableRedux = (selected) => ({
    type: SET_TRANSFER_AVAILABLE,
    payload: selected
});

// Action creator for setting transfer data
export const setTransferData = (data) => {
    return {
        type: SET_TRANSFER_DATA,
        payload: data,
    };
};

export const togglePowerButton = (isPressed) => ({
  type: TOGGLE_POWER_BUTTON,
  payload: isPressed,
});