import React from 'react';

import '../carousel/overviewPage/styles.css';
import OfferHeader from "../../header/offerHeader";
import './styles.css';

const CheckoutPage = ({details}) => {

    return (
        <div className="details-wrapper">
            <OfferHeader/>
            <div className="user-message">
                <p>
                    Dear Customer,<br/>
                    Thanks for reaching this far!<br/><br/>
                    We are in a Beta phase, hence no available purchase on the site.<br/>
                    We hope to enable it as soon as possible.<br/><br/>
                    Please come again!<br/><br/>
                    Best regards,<br/>
                    Picotrip
                </p>
            </div>
        </div>
    );
};

export default CheckoutPage;