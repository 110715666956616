import {
    UPDATE_OFFER_SELECTION,
    RESET_OFFERS,
    SET_IMAGE_INDEX,
    SET_HOTEL_ID,
    SET_FLIGHTS_SELECTED,
    UPDATE_OFFER_PAGE_INDEX,
    SAVE_OFFERS,
    SET_ACCOMMODATION_SELECTED
} from "../actions/offerActions";

const initialState = {
    selectedOffers: {},
    imageIndices: {}, // Store image indices for each hotel
    hotelId: null,    // Store the current hotel ID
    flightsSelected: false,
    accommodationSelected: false,
};

const offerReducer = (state = initialState, action) => {
    switch (action.type) {

        case UPDATE_OFFER_SELECTION: {
            const {itemId, optionId} = action.payload;
            const updatedSelectedOffers = {
                ...state.selectedOffers,
                [itemId]: optionId,
            };
            return {
                ...state,
                selectedOffers: updatedSelectedOffers,
            };
        }

        case RESET_OFFERS:
            return {
                ...state,
                selectedOffers: {},
                imageIndices: {}, // Reset image indices
                hotelId: null,    // Reset hotel ID
            };

        case SET_IMAGE_INDEX: {
            const {hotelId, index} = action.payload;
            return {
                ...state,
                imageIndices: {
                    ...state.imageIndices,
                    [hotelId]: index,
                },
            };
        }

        case SET_HOTEL_ID: {
            const {hotelId} = action.payload;
            return {
                ...state,
                hotelId,
            };
        }

        case SET_FLIGHTS_SELECTED: {
            return {
                ...state,
                flightsSelected: action.payload,
            };
        }
        case SET_ACCOMMODATION_SELECTED:
            return {
                ...state,
                accommodationSelected: action.payload
            };

        case UPDATE_OFFER_PAGE_INDEX: {
            const {offerPageIndex} = action.payload;
            return {
                ...state,
                offerPageIndex: offerPageIndex,
            }
        }

        case SAVE_OFFERS: {
            return {
                ...state,
                offerInRedux: action.payload,
            };
        }

        default:
            return state;
    }
};

export default offerReducer;
