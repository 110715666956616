import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import LoadingSpinner from '../../utils/loadingSpinner/loadingSpinner';

import {
    setTransferAvailableRedux,
    setTransferData,
    setTransferFetchedRedux
} from "../../../store/actions/transferActions";
import FlightsPage from "../flights/flightsPage";
import SearchAgainButton from "../../tripOrganisation/buttons/searchAgainButton";
import {
    processFlightToDestination,
    processAccommodationInfo,
    processFlightFromDestination,
    isOfferValid,
    generateOffer,
    getDestinationAirport,
} from "../../utils/dataProcessingFunctions";

import './styles.css';
import '../../../commonStyles.css';
import {
    calculateVacationDays,
    getGrandTotal
} from "../../utils/calculationFunctions";

import OffersNavigation from "../../footer/offersNavigation";
import AccommodationPage from "../accommodation/AccomodationPage";
import TransfersPage from "../transfers/TransfersPage";
import AsynchronousGetRequest from "../../api/asyncGetRequest";
import {saveOffers, setAccommodationSelected, setFlightsSelected} from "../../../store/actions/offerActions";
import OverviewPage from "./overviewPage/overviewPage";
import CheckoutPage from "../checkout/checkoutPage";

const NUMBER_OF_TRANSFER_POLL_ATTEMPTS = 5;
const TRANSFER_POLL_ATTEMPTS_DELAY = 3000;

const OffersCarousel = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {offers = []} = location.state || {};

    const selectedOffers = useSelector((state) => state.offer.selectedOffers);
    const offerInRedux = useSelector((state) => state.offer.offerInRedux);
    const transferData = useSelector((state) => state.transfer.data);

    const [transferFetched, setTransferFetched] = useState(false);
    const transferFetchedRedux = useSelector((state) => state.transfer.transferFetched);
    const transferSelectedRedux = useSelector((state) => state.transfer.transferSelected);
    const transferAvailableRedux = useSelector((state) => state.transfer.transferAvailable);

    const [transferAvailable, setTransferAvailable] = useState(true);

    const [loadingTransfers, setLoadingTransfers] = useState(true);
    const flightsSelectedRedux = useSelector((state) => state.offer.flightsSelected);
    const accommodationSelectedRedux = useSelector((state) => state.offer.accommodationSelected);

    const [grandTotal, setGrandTotal] = useState(0);
    const [processed, setProcessed] = useState(false); // Track if processing is complete

    // Fetch transfers if necessary
    useEffect(() => {
        const pollTransfers = async () => {
            console.log("transfer fetched in redux", transferFetched);
            if (!transferFetched) {
                for (let i = 0; i < NUMBER_OF_TRANSFER_POLL_ATTEMPTS; i++) {
                    try {
                        const path = 'api/fetch_transfers/';
                        const transferData = await AsynchronousGetRequest(path);
                        if (transferData.message?.[0]?.transfer_to_hotel && transferData.message?.[0]?.transfer_to_origin) {
                            dispatch(setTransferAvailableRedux(true));
                            dispatch(setTransferData(transferData));
                            dispatch(setTransferFetchedRedux(true));
                            setTransferFetched(true);
                            setTransferAvailable(true);
                            setLoadingTransfers(false);
                            console.log("settrans ava", transferAvailable);
                            break;
                        }
                        if (transferData.message === "No transfers found") {
                            console.log("no available transfers for this location");
                            setTransferAvailable(false);
                            console.log("settrans ava OUSIDE", transferAvailable);
                            dispatch(setTransferAvailableRedux(false));
                        }

                        await new Promise(resolve => setTimeout(resolve, TRANSFER_POLL_ATTEMPTS_DELAY));
                    } catch (error) {
                        console.error("Error fetching data:", error);
                    }
                    if (i === NUMBER_OF_TRANSFER_POLL_ATTEMPTS - 1) {

                        dispatch(setTransferAvailableRedux(false));
                        setTransferAvailable(false);
                        console.log("settrans ava FINIS", transferAvailable);
                    }
                }


            } else {
                setLoadingTransfers(false);
            }
        };

        if (offers.length > 0) {
            pollTransfers();
        }
    }, [offers, transferFetched, dispatch]);

    // Log transferAvailable when it changes
    useEffect(() => {
        console.log("transferAvailable updated:", transferAvailable);
    }, [transferAvailable]);

    // Log transferFetched when it changes
    useEffect(() => {
        console.log("transferFetched updated:", transferFetched);
    }, [transferFetched]);

    useEffect(() => {
        setTransferFetched(transferFetchedRedux);
    }, [transferFetchedRedux]);

    // Process offers and save to Redux
    useEffect(() => {
        if (!offerInRedux && !processed) {
            let processedOffers = {};

            processedOffers = generateOffer(offers);

            // Save processed offers to Redux
            dispatch(saveOffers(processedOffers));
            setProcessed(true); // Mark processing as complete
        }
    }, [offers, offerInRedux, processed, dispatch]);

    // Process specific data based on URL path
    useEffect(() => {
        if (offerInRedux && location.pathname === '/flights') {
            const currentRealOfferDetails = offers[0] || null;
            const validOffer = isOfferValid(currentRealOfferDetails);

            if (validOffer && process.env.REACT_APP_GET_OFFER_MODE !== 'mock') {
                if (currentRealOfferDetails?.offers?.data?.[1]?.[1]) {
                    currentRealOfferDetails.offers.data[1][1].forEach((hotelInfo, index) => {
                        processAccommodationInfo(hotelInfo, index + 1, offerInRedux.offers[2]);
                    });
                }
                processFlightToDestination(currentRealOfferDetails.offers.data, offerInRedux.offers[0].info1["selection-box"], offerInRedux.offers[0].info1.detailsGroup1);
                processFlightFromDestination(currentRealOfferDetails.offers.data, offerInRedux.offers[0].info1["selection-box-2"], offerInRedux.offers[0].info1.detailsGroup2);
            }
        }
    }, [location.pathname, offerInRedux, offers]);

    // Recalculate grand total on selection or URL change
    useEffect(() => {
        let total;
        if (offerInRedux) {
            total = getGrandTotal(selectedOffers, offerInRedux, flightsSelectedRedux, accommodationSelectedRedux, transferSelectedRedux);
            setGrandTotal(total);
        }
    }, [location.pathname, selectedOffers, offerInRedux, flightsSelectedRedux, accommodationSelectedRedux, transferSelectedRedux]);

    const renderCurrentComponent = () => {
        if (!offerInRedux) return null;

        switch (location.pathname) {
            case '/flights':
                return <FlightsPage
                    details={offerInRedux.offers[0]}
                    setFlightsSelected={setFlightsSelected}/>;
            case '/accommodation':
                return <AccommodationPage
                    setAccommodationSelected={setAccommodationSelected}/>;
            case '/transfers':
                return <TransfersPage transferData={transferData} offers={offerInRedux}
                                      destinationAirport={getDestinationAirport(offerInRedux)}/>;
            case '/overview':
                return <OverviewPage/>;
            case '/checkout':
                return <CheckoutPage/>
            default:
                return null;
        }
    };

    const handleNextClick = () => {
        if (offerInRedux) {
            switch (location.pathname) {
                case '/flights':
                    navigate('/accommodation', {state: {details: offerInRedux}});
                    break;
                case '/accommodation':
                    if (transferAvailable) {
                        navigate('/transfers', {state: {details: offerInRedux}});
                    } else {
                        navigate('/overview', {state: {details: offerInRedux}});
                    }
                    break;
                case '/transfers':
                    navigate('/overview', {state: {details: offerInRedux}});
                    break;
                case '/overview':
                    navigate('/checkout', {state: {details: offerInRedux}});
                default:
                    break;
            }
        }
    };

    const getStep = () => {
        switch (location.pathname) {
            case '/flights':
                return 1;
            case '/accommodation':
                return 2;
            case '/transfers':
                return 3;
            case '/overview':
                return 4;
            default:
                return 1;
        }
    };

    console.log("transferAvailable", transferAvailable);
    return (
        <div className="offers-container">
            {!offerInRedux ? (
                <LoadingSpinner/>
            ) : (
                offerInRedux && offerInRedux.offers.length ? (
                    <>
                        <div className="offers-list-wrapper">{renderCurrentComponent()}</div>
                        <OffersNavigation onNextClick={handleNextClick}
                                          step={getStep()}
                                          price={grandTotal}
                                          flightsSelected={flightsSelectedRedux}
                                          accommodationSelected={accommodationSelectedRedux}/>
                    </>
                ) : (
                    <div className="error-message">
                        <p>No offers available</p>
                        <SearchAgainButton onReset={() => navigate('/')}/>
                    </div>
                )
            )}
        </div>
    );
};

export default OffersCarousel;
