import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PlaneIconLanding from '../../../../images/icons/plane-landing.svg';
import PlaneIconTakeOff from '../../../../images/icons/plane-takeoff.svg';
import TaxiIcon from '../../../../images/icons/car-taxi-front.svg';
import HotelIcon from '../../../../images/icons/bed-double.svg';
import FlightInfo from './itemInfo/flightInfo';
import TransferInfo from './itemInfo/transferInfo';
import HotelInfo from './itemInfo/hotelInfo';
import './styles.css';
import {getGrandTotal, modifyDateTimeString} from '../../../utils/calculationFunctions';
import OffersNavigation from "../../../footer/offersNavigation";
import {useLocation, useNavigate} from "react-router-dom";
import OfferHeader from "../../../header/offerHeader";

const OverviewPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [transformedOffers, setTransformedOffers] = useState([]);
    const offerInRedux = useSelector((state) => state.offer.offerInRedux || []);
    const selectedOffers = useSelector((state) => state.offer.selectedOffers);
    const [grandTotal, setGrandTotal] = useState(0);
    const [transferSelected, setTransferSelected] = useState(false);
    const transferSelectedRedux = useSelector((state) => state.transfer.transferSelected);

    const nextButtonText = "Checkout";
    const REDUX_INDEX_TRANSFER_TO_ACCOMMODATION = 2;
    const REDUX_INDEX_TRANSFER_FROM_ACCOMMODATION = 4;

    const selectedOptionTransferToIndexFromRedux = useSelector((state) => {
        const offerDetails = state.offer.selectedOffers || {};
        return offerDetails[REDUX_INDEX_TRANSFER_TO_ACCOMMODATION];
    });

    const selectedOptionTransferFromIndexFromRedux = useSelector((state) => {
        const offerDetails = state.offer.selectedOffers || {};
        return offerDetails[REDUX_INDEX_TRANSFER_FROM_ACCOMMODATION];
    });

    useEffect(() => {
        if (offerInRedux.offers) {
            // Transform offers only when offerInRedux changes
            const transformed = createNewOffer([...offerInRedux.offers]); // Ensure no mutation
            setTransformedOffers(transformed);
        }

        // Add a slight delay to simulate loading
        const timeoutId = setTimeout(() => {
            setIsLoading(false);
        }, 100);

        return () => clearTimeout(timeoutId);
    }, [offerInRedux]);

    // Recalculate grand total on selection or URL change
    useEffect(() => {
        let total;
        if (offerInRedux) {
            total = getGrandTotal(selectedOffers, offerInRedux, true, true, transferSelectedRedux);
            setGrandTotal(total);
        }
    }, [offerInRedux]);

    // this function reworks offer so that part with id=5 is generated from return flights from id=1
    function createNewOffer(data) {
        const originalOffer = data.find((offer) => offer.id === 1);
        if (!originalOffer) {
            console.error("Offer with id:1 not found.");
            return data;
        }

        const detailsGroup2 = originalOffer.info1['detailsGroup2'] || [];
        const selectionBox2 = originalOffer.info1['selection-box-2'] || {};

        const newOffer = {
            id: 5,
            info1: {
                'detailsGroup1': detailsGroup2,
                'selection-box': selectionBox2,
            },
        };

        // Ensure no duplicates
        if (!data.find((offer) => offer.id === 5)) {
            data.push(newOffer);
        }

        return data;
    }

    const getIcon = (id) => {
        const icons = [PlaneIconTakeOff, TaxiIcon, HotelIcon, TaxiIcon, PlaneIconLanding];
        return icons[id - 1] || PlaneIconTakeOff;
    };

    const getLabelText = (id) => {
        switch (id) {
            case 1:
                return 'FLIGHT CHECK-IN';
            case 2:
                return 'TRANSFER TO ACCOMMODATION';
            case 4:
                return 'TRANSFER TO AIRPORT';
            case 3:
                return 'ACCOMMODATION CHECK-IN';
            case 5:
                return 'FLIGHT CHECK-IN';
            default:
                return 'PICOTRIP';
        }
    };

    const getHighlightedInfo = (id) => {
        if (id === 2 || id === 4) {
            console.log("here am I now");
            return true;
        }
        return true; // Simplified for brevity
    };

    const getLabelTime = (id, offer) => {
        const item = offer.find((data) => data.id === id);
        if (!item) {
            console.error(`Item with id ${id} not found in the offer.`);
            return '';
        }

        const selectedIndex = selectedOffers?.[id] || 0;

        let displayTime = '';
        switch (id) {
            case 1:
            case 5:
                displayTime = item.info1?.detailsGroup1[selectedIndex]?.segments[0]?.departure?.at || 'No departure time available';
                return modifyDateTimeString(displayTime);
            case 2:
            case 4:
                displayTime = item.info1?.['selection-box']?.[`option${selectedIndex + 1}`]?.time || '';
                return modifyDateTimeString(displayTime);
            case 3:
                displayTime = item.info1?.detailsGroup1[selectedIndex]?.segments[0]?.checkInTime || 'No dateTime available';
                return displayTime.slice(0, 5);
            default:
                return '';
        }
    };

    const handleNextClick = () => {
        if (offerInRedux) {
            switch (location.pathname) {
                case '/overview':
                    navigate('/checkout', {state: {details: offerInRedux}});
                    break;
                default:
                    break;
            }
        }
    };

    const renderItemContent = (item) => {
        const details = transformedOffers[item.id - 1];

        if (!details) {
            return <span>Loading...</span>;
        }

        const selectedIndex = selectedOffers?.[item.id] || 0;

        switch (item.id) {
            case 1:
            case 5:
                const firstSegment = details.info1.detailsGroup1[selectedIndex].segments[0] || "No flight data available";
                const segments = details.info1.detailsGroup1[selectedIndex].segments;
                const lastSegment = segments[segments.length - 1] || firstSegment;
                const totalDuration = details.info1.detailsGroup1[selectedIndex].totalDuration || "No duration info";

                console.log("examine last seg", lastSegment);
                return (
                    <FlightInfo
                        flightDetails={{
                            departure: firstSegment.departure || "No departure info",
                            arrival: lastSegment.arrival || "No arrival info",
                            segments: details.info1.detailsGroup1[selectedIndex].segments || "No flight info",
                            totalDuration: totalDuration,
                            item: item.id,
                        }}
                    />
                );
            case 2:
                const isTransferToSelected = [0, 1, 2].includes(selectedOptionTransferToIndexFromRedux);
                return <TransferInfo transferDetails={details} isEnabled={getHighlightedInfo(item.id)}
                                     isSelected={isTransferToSelected}/>;
            case 4:
                const isTransferFromSelected = [0, 1, 2].includes(selectedOptionTransferFromIndexFromRedux);
                return <TransferInfo transferDetails={details} isEnabled={getHighlightedInfo(item.id)}
                                     isSelected={isTransferFromSelected}/>;
            case 3:
                return <HotelInfo hotelDetails={details} index={selectedIndex}/>;
            default:
                return <span>{item.title}</span>;
        }
    };

    return (
        <div className="timeline-container">
            {isLoading ? (
                <p className="loading-text">Loading data...</p>
            ) : (
                <>
                    <OfferHeader/>
                    {transformedOffers.map((item) => (
                        <React.Fragment key={item.id}>
                            <div className="timeline-wrapper">
                                <div className="item-label">
                                    <p className="label-description">{getLabelText(item.id)}</p>
                                    <p className="label-time">{getLabelTime(item.id, transformedOffers)}</p>
                                </div>
                                <div className="timeline-item rounded-button">
                                    <div className="timeline-icon rounded-left-button">
                                        <div className="timeline-icon-inner rounded-left-button">
                                            <img src={getIcon(item.id)} alt=""/>
                                        </div>
                                    </div>
                                    <div className="event-container">
                                        <div className="event-container-inner">
                                            <div
                                                className={`event-info ${getHighlightedInfo(item.id) ? "highlight" : ""}`}>
                                                {renderItemContent(item)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                    <OffersNavigation onNextClick={handleNextClick}
                                      step={4}
                                      price={grandTotal}
                                      flightsSelected={true}
                                      accommodationSelected={true}
                                      nextButtonText={nextButtonText}
                    />
                </>

            )}
        </div>
    );
};

export default OverviewPage;
