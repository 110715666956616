import DestinationInfo from "../offersPage/buttons/cityButton";
import {ReactComponent as LogoIcon} from "./../../images/header/picotrip.svg";
import React, {useEffect} from "react";
import './styles.css';
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";

const OfferHeader = () => {

    const navigate = useNavigate();

    const handleClickOnLogo = () => {
        navigate("/search");
    }

    useEffect(() => {
        // This code runs AFTER the component has rendered

        function setColor(id, newColor) {
            const element = document.getElementById(id); // Get element by ID
            if (element) {  // Important check!
                element.style.fill = newColor;
            }
        }

        // Use document.documentElement to access the root element
        const color = getComputedStyle(document.documentElement).getPropertyValue('--monitor-shadow-color').trim();

        setColor('logo1', color);
        setColor('logo2', color);

    }, []); // Empty dependency array ensures this runs only once after mount


    const destinationName = Cookies.get('destination') || '';

    return (
        <div className={"header"}>
            <div className="logo">
                <LogoIcon id="logo" onClick={handleClickOnLogo}></LogoIcon>
            </div>
            <DestinationInfo cityName={destinationName}/>
        </div>
    );
}

export default OfferHeader;