import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import UserDataEntryStep from './steps/initialDataEntry/userDataEntryStep';
import BudgetSmartphoneStep from './steps/setBudget/budgetSmartphoneStep';
import CitiesPickStep from './steps/chooseCities/cityPickStep';
import TagSelection from './steps/setActivityTag/typeOfTravelStep';
import PostRequest from '../api/postRequest';
import GetRequest from '../api/getRequest';
import Cookies from 'js-cookie';
import LoadingSpinner from "../utils/loadingSpinner/loadingSpinner";
import {useDispatch} from "react-redux";
import {resetOffers} from "../../store/actions/offerActions";
import {
    resetTransferFetched,
    setTransferAvailableRedux,
    setTransferSelectedRedux,
    togglePowerButton
} from "../../store/actions/transferActions";
import SearchAgainButton from "./buttons/searchAgainButton";
import '../../commonStyles.css';

const SearchForm = () => {
    const [formData, setFormData] = useState({
        departureLocation: '',
        adults: 0,
        children: 0,
    });
    const [dateRange, setDateRange] = useState({
        departureDate: null,
        returnDate: null,
    });
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedCities, setselectedCities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [fetchError, setFetchError] = useState(null);

    const {stepId} = useParams();
    const [step, setStep] = useState(parseInt(stepId) || 1);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (stepId) setStep(parseInt(stepId));
    }, [stepId]);

    // Fetch offers automatically at Step 5
    useEffect(() => {
        if (step === 4) {
            dispatch(resetOffers());
            dispatch(resetTransferFetched());
            dispatch(togglePowerButton(false)); // Reset button state on component load
            dispatch(setTransferAvailableRedux(true));
            dispatch(setTransferSelectedRedux(false));
            const fetchData = async () => {
                const fetchedOffers = await fetchOffers();
                if (fetchedOffers) {
                    console.log('Offers fetched successfully.');

                    navigate('/flights', {state: {offers: fetchedOffers, isLoading: false}});
                } else {
                    return (
                        <>
                            <div className="error-detail">
                                Failed to get any offers. Please try again with different search criteria.
                            </div>
                            <SearchAgainButton/>
                        </>
                    );
                }
            };
            fetchData();
        }
    }, [step, navigate, dispatch]);

    // Fetch offers from API
    const fetchOffers = async () => {
        if (process.env.REACT_APP_GET_OFFER_MODE !== 'mock') {
            setIsLoading(true);
            try {
                const getOffers = GetRequest('api/get_offers/');
                const data = await getOffers();
                if (data.message) {
                    const formattedOffers = Object.keys(data.message).map((offerId) => ({
                        offerId,
                        offers: data.message[offerId]
                    }));
                    return formattedOffers;
                } else {
                    throw new Error('Failed to load offers');
                }
            } catch (error) {
                console.error("Error fetching offers:", error);
                setFetchError(error.message);
                return null;
            } finally {
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
            navigate('/flights');
        }

    };

    // Handle step transition, store `activityTag` in cookie at Step 1
    const nextStep = async ({startDate = null, endDate = null, tagId = null} = {}) => {
        if (step === 1) {
            // Store the activity tag in state instead of cookies if needed
            console.log("tagId", tagId)
            if (!tagId) {
                tagId = "hiking";
            }
            setSelectedTags(prevTags => [...prevTags, tagId]); // Update selectedTags state
            await sendApiInitialData(tagId, startDate, endDate);
        }
        goToStep(step + 1);
    };


    const goToStep = (stepNumber) => {
        setStep(stepNumber);
        navigate(`/search/${stepNumber}`);
    };

    const prevStep = () => {
        const newStep = step > 1 ? step - 1 : 1;
        setStep(newStep);
        goToStep(newStep);
    };

    const formatDateToLocal = (date) => {
        if (!date) return null;
        const offset = date.getTimezoneOffset();
        return new Date(date.getTime() - offset * 60 * 1000).toISOString().split('T')[0];
    };

    const sendApiInitialData = async (activityTag, startDate, endDate) => {

        const requestParams = JSON.stringify({
            ...formData,
            activityTag: activityTag,
            destination: '',
            departureDate: startDate ? formatDateToLocal(startDate) : null,
            returnDate: endDate ? formatDateToLocal(endDate) : null,
        });

        const requestData = {message: JSON.parse(requestParams)};
        try {
            const data = await PostRequest(requestData, 'api/start/', 'json');
            if (data) {
                Cookies.set('request_params', requestParams, {
                    sameSite: window.location.protocol === 'https:' ? 'None' : 'Lax',
                    secure: window.location.protocol === 'https:',
                    path: '/'
                });
            } else {
                console.error('No task_id found in response data.');
            }
        } catch (error) {
            console.error('Fetch failed:', error);
        }
    };

    const handleChange = (name) => (newValue) => {
        setFormData(prevFormData => ({...prevFormData, [name]: newValue}));
    };

    const handleInputChange = (name) => (e) => {
        setFormData(prevFormData => ({...prevFormData, [name]: e.target.value}));
    };

    const handleRangeSelect = (range) => {
        setDateRange(range);
    };

    // In SearchForm component
    const resetFormAndNavigate = () => {
        // Reset form data and other relevant state variables
        setFormData({
            departureLocation: '',
            destination: '',
            adults: 0,
            children: 0,
        });
        setDateRange({
            departureDate: null,
            returnDate: null,
        });
        setSelectedTags([]);
        setselectedCities([]);
        setFetchError(null);
        setIsLoading(false);
        // Navigate to the initial step
        navigate('/search/1');
    };


    return (
        <>
            {step === 1 && (
                <UserDataEntryStep
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleChange={handleChange}
                    handleInputChange={handleInputChange}
                    values={formData}
                    onRangeSelect={handleRangeSelect}
                    selectedTags={selectedTags}
                />
            )}
            {step === 2 && (
                <CitiesPickStep
                    nextStep={nextStep}
                    prevStep={prevStep}
                />
            )}
            {step === 3 && (
                <BudgetSmartphoneStep
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleInputChange={handleInputChange}
                    selectedCities={selectedCities}
                />
            )}
            {step === 4 && (
                <div>
                    {isLoading ? (
                        <LoadingSpinner/>
                    ) : fetchError ? (
                        <div>
                            <div className="error-message">
                                <p>Error: {fetchError}</p>
                                <p>Please try again later.</p>
                            </div>
                            <SearchAgainButton onReset={resetFormAndNavigate}/>
                        </div>
                    ) : (
                        <p>Offers fetched successfully. Redirecting...</p>
                    )}
                </div>
            )}
        </>
    );
};

export default SearchForm;
