import React from 'react';
import './styles.css';
import CustomNextButton from "../tripOrganisation/buttons/customNextButton";

const CityPopupNavigation = ({
                                 onNextClick,
                                 buttonText
                             }) => {

    return (
        <div className={"navigation-wrapper"}>
            <div className={"navigation-wrapper"}>
                <div className="navigation">
                    {
                        <CustomNextButton onClick={onNextClick} text={buttonText} isReady={true}/>
                    }
                </div>
            </div>
        </div>
    );
};

export default CityPopupNavigation;
