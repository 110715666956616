import React, { useState } from "react";
import "./styles.css";
import {ReactComponent as  CloseIcon} from '../../../images/icons/x.svg';

const CloseButton = ({ onClick }) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleClick = () => {
    setIsPressed(true);
    setTimeout(() => {
      setIsPressed(false);
    }, 300);
    onClick();
  };

  return (
    <div
      className={`button-x-outer nav-button-outer rounded-button ${isPressed ? "pressed" : ""}`}
      onClick={handleClick}
    >
      <div className={`button-x-inner nav-button-inner rounded-button ${isPressed ? "translate" : ""}`}>
        <CloseIcon/>
      </div>
    </div>
  );
};

export default CloseButton;
