import React from "react";
import "../styles.css";
import "../carousel/styles.css";

const DestinationInfo = ({cityName}) => {

    return (

        <div className={`button-city`}>
            <div className={`button-city-inner`}>
                <p className="destination-name">Trip to {cityName}</p>
            </div>
        </div>
    );
};

export default DestinationInfo;

