import React, {useEffect} from 'react';
import './styles.css'; // Import the styles for fullscreen view
import {ReactComponent as LeftImageIcon} from "../../../../../images/icons/chevron-left-screen-light.svg";

const FullScreenImageViewer = ({images, currentIndex, onClose, onNext, onPrev}) => {

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            } else if (event.key === 'ArrowRight') {
                onNext();
            } else if (event.key === 'ArrowLeft') {
                onPrev();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [onClose, onNext, onPrev]);

       useEffect(() => {
        // This code runs AFTER the component has rendered

        function setColor(svgId, newColor) {
            const svgElement = document.getElementById(svgId); // Get SVG element by ID
            if (svgElement) {
                const pathElement = svgElement.querySelector('path'); // Get the <path> element inside the SVG
                if (pathElement) {
                    pathElement.style.stroke = newColor; // Update the stroke color of the <path>
                }
            }
        }

        // Use document.documentElement to access the root element
        const color = getComputedStyle(document.documentElement).getPropertyValue('--screen-shadow').trim();

        // Update the stroke color for both arrows
        setColor('arrow-prev', color);
        setColor('arrow-next', color);

    }, []); // Empty dependency array ensures this runs only once after mount

        return (
        <div className="fullscreen-overlay" onClick={onClose}>
            <div className="fullscreen-content" onClick={(e) => e.stopPropagation()}>
                <img src={images[currentIndex]} alt={`Image ${currentIndex + 1}`} className="fullscreen-image"/>
                <div className="image-counter">{currentIndex + 1} / {images.length}</div>
                <button className="nav-button prev-button" onClick={onPrev}>
                    <LeftImageIcon id="arrow-prev" /> {/* Add unique ID */}
                </button>
                <button className="nav-button next-button" onClick={onNext}>
                    <LeftImageIcon id="arrow-next" style={{ transform: 'rotate(180deg)' }} /> {/* Add unique ID */}
                </button>
                <button className="close-button" onClick={onClose}>X</button>
            </div>
        </div>
    );
};

export default FullScreenImageViewer;
