import React from "react";

import "./styles.css";
import RadarScan from "../sonarAnimation/sonar";

const SearchResults = ({loading, ready, data}) => {

    if (loading) {
        return (
            <div className={"results-wrapper"}>
                <RadarScan/>
            </div>
        );
    } else if (ready && data.length > 0) {
        return (
            <div className="results-wrapper">
                <div className="scrollable-wrapper">
                    {data.map((city) => (
                        <div key={city.geonameid} className="city-teaser strong-shadow">
                            <div className={"left-info"}>
                                <div className={"city-name"}>{city.name}</div>
                                <div className={"country-name"}>{city.country}</div>
                            </div>
                            <div className={"right-info"}>
                                <div className={"transport-type"}></div>

                            </div>


                        </div>
                    ))}
                </div>
            </div>
        );
    } else if (ready) {
        return (
            <div className="results-wrapper">
                <p>No results found.</p>
            </div>
        );
    }
    return null;

};

export default SearchResults;
