// In SearchAgainButton component
import React, {useState} from 'react';
import './styles.css';

const SearchAgainButton = ({onReset}) => {
    const [isPressed, setIsPressed] = useState(false);

    const handleClick = () => {
        setIsPressed(true);
        setTimeout(() => {
            setIsPressed(false);
        }, 300);
        // Call the reset function passed from the parent
        onReset();
    };

    return (
        <div
            className={`button-search-outer nav-button-outer ready ${isPressed ? 'pressed' : ''}`}
            onClick={handleClick}
        >
            <div className={`button-search-inner nav-button-inner ready ${isPressed ? 'translate' : ''}`}>
                Search Again
            </div>
        </div>
    );
};

export default SearchAgainButton;
