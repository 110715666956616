import React from "react";
import "../styles.css";
import {ReactComponent as PlaneIcon} from '../../../images/icons/offersPage/plane.svg';
import {ReactComponent as AccommodationIcon} from '../../../images/icons/offersPage/bed-dark-green.svg';
import {ReactComponent as TransferIcon} from '../../../images/icons/offersPage/car-taxi-front-dark-green.svg';
import {useSelector} from "react-redux";

const OfferSelectionMonitor = ({step, price}) => {
    const REDUX_INDEX_TRANSFER_TO_ACCOMMODATION = 2;
    const REDUX_INDEX_TRANSFER_FROM_ACCOMMODATION = 4;
    const REDUX_INDEX_ACCOMMODATION = 3;

    const selectedOptionTransferToIndexFromRedux = useSelector((state) => {
        const offerDetails = state.offer.selectedOffers || {};
        return offerDetails[REDUX_INDEX_TRANSFER_TO_ACCOMMODATION];
    });

    const selectedOptionTransferFromIndexFromRedux = useSelector((state) => {
        const offerDetails = state.offer.selectedOffers || {};
        return offerDetails[REDUX_INDEX_TRANSFER_FROM_ACCOMMODATION];
    });

    const selectedOptionAccommodationIndexFromRedux = useSelector((state) => {
        const offerDetails = state.offer.selectedOffers || {};
        return offerDetails[REDUX_INDEX_ACCOMMODATION];
    });

    return (
        <div
            className={`button-selection-outer rounded-left-button`}
        >
            <div className={`button-selection-inner rounded-left-button`}>
                <div className={`small-monitor`}>
                    {step >= 1 && (
                        <>
                            <PlaneIcon/>
                            {step >= 2 &&
                                [0, 1, 2].includes(selectedOptionAccommodationIndexFromRedux) && (
                                    <>
                                        +
                                        <AccommodationIcon/>
                                    </>
                                )}
                            {step >= 3 &&
                                [0, 1, 2].includes(selectedOptionTransferToIndexFromRedux) && (
                                <>
                                    +
                                    <TransferIcon/>
                                </>
                            )}
                        </>
                    )}
                    ={price}
                </div>

            </div>
        </div>
    );
};

export default OfferSelectionMonitor;
