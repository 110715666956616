import React, {useState, useRef, useEffect} from "react";

import Autocomplete from "../../autocomplete/autocomplete";
import "./styles.css";
import "../styles.css";
import "../../../../commonStyles.css";
import CustomCalendar from "../../datepicker/datepicker";
import {useSelector} from "react-redux";
import Header from "../../../header/header";
import Cookies from "js-cookie";
import SearchResults from "../../userQuestions/searchResults";
import TagSelection from "../setActivityTag/typeOfTravelStep";
import GetRequest from "../../../api/getRequest";
import {formatDisplayDate} from "../../../utils/dataProcessingFunctions";
import XButtonImage from '../../../../images/icons/x.svg';

function UserDataEntryStep({
                               nextStep,
                               handleInputChange,
                               values,
                           }) {
    const [isValidSelection, setIsValidSelection] = useState(false);

    const [whereFromExpanded, setWhereFromExpanded] = useState(true);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [tagsExpanded, setTagsExpanded] = useState(false);

    const [searchResultsReady, setSearchResultsReady] = useState(false);
    const [searchResultsDisplayed, setSearchResultsDisplayed] = useState(false);
    const [inputFieldsCollapsed, setInputFieldsCollapsed] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const errorMessageAirportRef = useRef(null);
    const autocompleteRef = useRef(null);
    const calendarRef = useRef(null);
    const tagContainerRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [startingPoint, setStartingPoint] = useState(Cookies.get('startingPoint') || '');
    const [selectedTag, setSelectedTag] = useState(null); // State to store selected tag
    const [error, setError] = useState(null); // State to store selected tag
    const [responseData, setResponseData] = useState(null); // State to store selected tag

    const startDateFromRedux = useSelector((state) => state.tripOrganisation.startDate);
    const endDateFromRedux = useSelector((state) => state.tripOrganisation.endDate);

    useEffect(() => {
        setStartDate(startDateFromRedux);
    }, [startDateFromRedux]);

    useEffect(() => {
        setEndDate(endDateFromRedux);
    }, [endDateFromRedux]);

    useEffect(() => {
        fetchUserLocation();
    }, []);

    useEffect(() => {
        if (startingPoint) {
            console.log("starting point is set ");
            setIsValidSelection(true);
            // Update the parent component with the selected airport code
            handleInputChange('departureLocation')({target: {value: startingPoint}});
        }
    }, [startingPoint]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setCalendarOpen(false);
            } else {
                setCalendarOpen(true);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [calendarRef]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tagContainerRef.current && !tagContainerRef.current.contains(event.target)) {
                setTagsExpanded(false);
            } else {
                setTagsExpanded(true);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [tagContainerRef]);

    useEffect(() => {
        const handleClick = (event) => {
            if (autocompleteRef.current) {
                if (autocompleteRef.current.contains(event.target)) {
                    setWhereFromExpanded(true);
                } else {
                    setWhereFromExpanded(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);


    const handleTagSelection = (tagId) => {
        setSelectedTag(tagId);
    };

    // Function to get user location
    const fetchUserLocation = async () => {
        try {
            const response = await GetRequest('/api/get_initial_location');
            console.log('User location data:', response);

            const response_formatted = `${response.city}, ${response.country}`;

            console.log("response_formatted", response_formatted)

            setStartingPoint(response_formatted);

            // If you want to send the location somewhere else
            // sendCoordinates(response);
        } catch (error) {
            console.error('Failed to fetch user location:', error);
        }
    };

    const handleSearchClick = async () => {
        try {
            setTagsExpanded(false);

            // Validate required fields
            if (!values.departureLocation) {
                throw new Error('Please select a starting point');
            }
            if (!startDate || !endDate) {
                throw new Error('Please select travel dates');
            }

            // Format dates as dd-mm-yyyy
            const formatDate = (date) => {
                if (!date) return '';
                const d = new Date(date);
                const day = String(d.getDate()).padStart(2, '0');
                const month = String(d.getMonth() + 1).padStart(2, '0');
                const year = d.getFullYear();
                return `${day}-${month}-${year}`;
            };

            // Use different variable names for formatted dates
            const formattedBeginDate = formatDate(startDate);
            const formattedEndDate = formatDate(endDate);

            setIsLoading(true);

            const data = await GetRequest(
                `/api/get_trips_info?from=${encodeURIComponent(values.departureLocation)}` +
                `&begin=${formattedBeginDate}&end=${formattedEndDate}` +
                `&activityType=${selectedTag || ''}`
            );

            setSearchResultsReady(true);
            setSearchResultsDisplayed(true);
            setInputFieldsCollapsed(true);
            setResponseData(data);
            return data;
        } catch (error) {
            console.error('Search failed:', error);
            // Add error state to show to user
            setError(error.message);
            return null;
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className={"full-content-wrapper"}>
            <Header title={"Picotrip"}/>
            <div id={"container"} className="form-inline-wrapper user-entry-length">
                {!inputFieldsCollapsed &&
                    <div className={"user-entry-container"}>
                        {!searchResultsDisplayed && searchResultsReady &&
                            <div className={"x-button-results"}>
                                <img src={XButtonImage} alt="Clear Input"
                                     onClick={
                                         () => {
                                             setSearchResultsDisplayed(true);  // This will open the calendar
                                             setInputFieldsCollapsed(true);
                                         }
                                     }/>
                            </div>
                        }

                        <div id={"autocomplete"} className="autocomplete-wrapper" ref={autocompleteRef}>
                            <Autocomplete
                                ref={errorMessageAirportRef}
                                handleInputChange={handleInputChange}
                                values={values}
                                isValidSelection={isValidSelection}
                                setIsValidSelection={setIsValidSelection}
                                startingPoint={startingPoint}
                                expanded={whereFromExpanded}
                                onNextClick={() => {
                                    setCalendarOpen(true);  // This will open the calendar
                                    setWhereFromExpanded(false);
                                }}
                            />
                        </div>
                        <div id={"datepicker"} className={"datepicker-wrapper"} ref={calendarRef}>
                            <CustomCalendar
                                isOpen={calendarOpen}
                                onClose={() => {
                                    setCalendarOpen(false);
                                    setTagsExpanded(true);  // Add this line
                                }}
                                selectedRange={startDate && endDate ? {start: startDate, end: endDate} : null}
                            />
                        </div>
                        <div id={"tag-selection"} ref={tagContainerRef}>
                            <TagSelection tagsExpanded={tagsExpanded} onTagSelect={handleTagSelection}
                                          onSearchClick={handleSearchClick}/>
                        </div>

                    </div>
                }
                {inputFieldsCollapsed && <div className={"user-entry-container"}
                                              onClick={() => {
                                                  setInputFieldsCollapsed(false);
                                                  setWhereFromExpanded(true)
                                                  setCalendarOpen(false);
                                                  setTagsExpanded(false);
                                                  setSearchResultsDisplayed(false);
                                              }}>
                    <div className={"collapsed-input strong-shadow"}>
                        {(() => {
                            const dateDisplay = formatDisplayDate(startDate, endDate);
                            return (
                                <>
                                    <div className={"activity-name-collapsed"}>{selectedTag}</div>
                                    <div className={"time-range-collapsed"}>{dateDisplay.start} - {dateDisplay.end}</div>
                                </>);
                        })()}
                    </div>
                </div>}
                <>
                    {searchResultsReady && searchResultsDisplayed && (
                        <div className={"travel-type-selection"}>
                            <div className={"all-types"}><p>Any</p></div>
                            <div className={"by-car"}><p>Car</p></div>
                            <div className={"by-plane"}><p>Plane</p></div>
                        </div>
                    )}
                    <div className={"results-container"}>
                        <SearchResults loading={isLoading} ready={searchResultsReady && searchResultsDisplayed}
                                       data={responseData}/>
                    </div>
                </>

            </div>
        </div>
    );
}

export default UserDataEntryStep;